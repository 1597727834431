import { API } from "aws-amplify";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { Button, Form } from "react-bootstrap";
import { Link, Outlet, useParams } from "react-router-dom";
import ClearContent from "../components/ClearContent";
import Directions from "../components/Directions";
import HelpTip from "../components/HelpTip";
import LoadingOrError from "../components/LoadingOrError";
import PageContainer from "../components/PageContainer";
import SaveToFolder from "../components/SaveToFolder";
import TheTaskSufPref from "../components/TheTaskSufPref";
import { getWordCount } from "../helpers/getWordCount";
import saveToFolder from "../helpers/saveToFolder";
import { useDocumentTitle } from "../helpers/useDocumentTitle";
import { useStickyState } from "../helpers/useStickyState";

const keyName = "sufpref";

const helpData = {
  mainTitle: "Suffix & Prefix Matching",
  title1: "What is Suffix & Prefix Matching?",
  text1: `The Suffix & Prefix Matching application replaces suffixes and 
  prefixes of words with an ellipsis (…). Learners will match the correct 
  suffix/prefix to the root word in a sentence.`,
  title2: "What is the purpose of Suffix & Prefix Matching?",
  text2: `Suffix & Prefix matching tasks are helpful for practicing word 
  formation and enhancing learners’ awareness of word classes in English. 
  Learners will improve their English vocabulary and accuracy of written and 
  spoken language.  
  `,
  title3: "How do I use the Suffix & Prefix Matching app?",
  text3: (
    <ol>
      <li>
        Paste a paragraph/sentence of an appropriate length and complexity level
        for your students in the text box [Enter your text here]. You can expand
        the text box by dragging with the mouse on the bottom, right corner.
      </li>
      <li>
        Click on <strong>Create task</strong> button.
      </li>
      <li>
        Enter task directions and click
        <strong> Save to folder</strong>, go to your
        <strong>
          {" "}
          <Link to="/folder">Folder</Link>{" "}
        </strong>{" "}
        to print out your worksheet.
      </li>
      <li>
        Note that inputs from your previous session are retained if you work on
        the same machine and browser
      </li>
    </ol>
  ),
  keyName: keyName,
};

const myParams = {
  directions: {
    "suffix-matcher": [
      `A. Complete the words in the following sentences with the suffixes 
  provided. B. Underline/circle the words in which the deleted letters are 
  part of the root, and not a suffix. C. Determine the function of suffixes – 
  'noun', 'verb', 'adjective' or 'adverb' forming.`,
    ],
    "prefix-matcher": [
      `A. Complete the words in the following sentences with the prefixes 
    provided. B. Underline/circle the words in which the deleted letters are 
    part of the root, and not a prefix. C. Determine the meaning of prefixes.`,
    ],
  },
};

export default function SufPrefOutlet() {
  return <Outlet />;
}

export const SufPrefs = ({ signOut }) => {
  let params = useParams();
  let sufprefID = params.sufprefID;
  // if user navigates to sufpref then default to suffix
  if (!sufprefID) {
    sufprefID = "suffix-matcher";
  }

  let sufOrPref = useRef(sufprefID);
  const [workSheet, setWorkSheet] = useStickyState([]);
  const [formStore, setFormStore] = useStickyState({}, "eng-apps-" + keyName);
  const [data, setData] = useState({
    keyName: sufprefID,
    defs: null,
    dir_text: myParams.directions[sufprefID][0],
  });
  const [isSending, setIsSending] = useState(false);
  const [error, setError] = useState();
  const [saveFolder, setSaveFolder] = useState(false);
  const [showFolder, setShowFolder] = useState(false);
  const [textValue, setTextValue] = useState(formStore.textArea);
  const [directionText, setDirectionText] = useState(formStore.myOwnDir);
  const [ownCheck, setOwnCheck] = useState(false);
  const [dirSelect, setDirSelect] = useState(myParams.directions[sufprefID][0]);

  // need to reset data if one switched from suffix to prefix of vice versa
  // otherwise wrong test/keys are displayed
  useEffect(() => {
    if (sufprefID !== sufOrPref.current) {
      sufOrPref.current = sufprefID;
      setData({
        ...data,
        defs: null,
        dir_text: myParams.directions[sufprefID][0],
        keyName: sufprefID,
      });
    }
  }, [sufOrPref, sufprefID, data]);

  const handleInputChange = (event) => {
    const target = event.target;
    const id = target.id;

    if (id === "textarea") {
      setTextValue(target.value);
      setFormStore({ ...formStore, textArea: target.value });
    } else if (id === "directionText") {
      setData({ ...data, dir_text: target.value });
      setFormStore({
        ...formStore,
        dir_text: target.value,
        myOwnDir: target.value,
      });
      setDirectionText(target.value);
    } else if (id === "ownCheck") {
      // if currently checked then user wants to un-check, hence we need to set
      // to predefined values
      let newDirText = ownCheck
        ? myParams.directions[sufprefID][0]
        : directionText;
      setOwnCheck(!ownCheck);
      setFormStore({
        ...formStore,
        dir_text: newDirText,
      });
      setData({ ...data, dir_text: newDirText });
    } else if (id === "dirSelect") {
      setDirSelect(target.value);
      setData({ ...data, dir_text: target.value });
    }
  };

  let sufprefWord = sufprefID.slice(0, 6);
  let pageTitle = useDocumentTitle(sufprefID, helpData.text1);
  let titleText = `${helpData.mainTitle}, ${pageTitle}`;

  const myInit = useMemo(
    () => ({
      headers: { "Content-Type": "application/json" },
      body: {
        textarea: textValue,
        nthword: null,
        custom_words: null,
      },
    }),
    [textValue]
  );

  const memoizedCallback = useCallback(
    (event) => {
      // need to disable submit buttons post action
      event.preventDefault();
      // need to prevent more than one request sending
      if (isSending) return;
      setIsSending(true);
      API.post("apienglishreactamplify", `/items/${sufprefID}`, myInit)
        .then((response) => {
          setData({ ...data, defs: response });
        })
        .then(() => setIsSending(false))
        .catch(setError);
    },
    [isSending, sufprefID, myInit, data]
  );

  const wordCount = getWordCount(textValue);

  return (
    <PageContainer title={titleText} signOut={signOut}>
      <HelpTip dictIn={helpData} />
      <Form onSubmit={memoizedCallback}>
        <Form.Group>
          <Form.Label htmlFor="textarea">
            1. Paste or type a paragraph/sentence below.
          </Form.Label>
          {textValue && textValue.length > 0 && (
            <ClearContent
              setBox={setTextValue}
              formStore={formStore}
              setForm={setFormStore}
              formKey="textArea"
            />
          )}
          <Form.Control
            as="textarea"
            id="textarea"
            rows="3"
            value={textValue}
            onChange={handleInputChange}
            required
            placeholder="Enter your text here."
          />
          <Form.Text className="text-muted">Word count: {wordCount}</Form.Text>
        </Form.Group>
        <Button type="submit" disabled={isSending} className="mb-3">
          Create task
        </Button>{" "}
        {(isSending || error) && (
          <LoadingOrError loading_={isSending} error_={error} />
        )}
      </Form>
      {data.defs && (
        <Form
          onSubmit={(e) =>
            saveToFolder(
              e,
              workSheet,
              data,
              setWorkSheet,
              setShowFolder,
              setSaveFolder
            )
          }
        >
          <Directions
            dirOrder="2"
            dirSelect={dirSelect}
            directionText={directionText}
            formStore={formStore}
            handleInputChange={handleInputChange}
            myParamsDirections={myParams.directions[sufprefID]}
            ownCheck={ownCheck}
            setDirectionText={setDirectionText}
            setFormStore={setFormStore}
          />
          <TheTaskSufPref data={data} sufprefWord={sufprefWord} />
          <SaveToFolder saveFolder={saveFolder} showFolder={showFolder} />
        </Form>
      )}
    </PageContainer>
  );
};
