import { faArrowDown, faArrowUp } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useRef, useState } from "react";
import {
  Button,
  ButtonGroup,
  Col,
  Container,
  Form,
  Image,
  Row,
} from "react-bootstrap";
import { useReactToPrint } from "react-to-print";
import FolderItem from "../components/FolderItem";
import PageContainer from "../components/PageContainer";
import TheTaskClozeTest from "../components/TheTaskClozeTest";
import TheTaskDictMultiple from "../components/TheTaskDictMultiple";
import TheTaskFrequentWord from "../components/TheTaskFrequentWord";
import TheTaskHighlight from "../components/TheTaskHighlight";
import TheTaskMatching from "../components/TheTaskMatching";
import TheTaskMultipleChoice from "../components/TheTaskMultipleChoice";
import TheTaskQuestionMaker from "../components/TheTaskQuestionMaker";
import TheTaskSufPref from "../components/TheTaskSufPref";
import TheTaskSynonyms from "../components/TheTaskSynonyms";
import TheTaskWordShuffler from "../components/TheTaskWordShuffler";
import { useDocumentTitle } from "../helpers/useDocumentTitle";
import { useStickyState } from "../helpers/useStickyState";

function FileInput({ imageURL, setImageURL }) {
  const fileInput = useRef(null);

  const fileTypes = [
    "image/png",
    "image/jfif",
    "image/pjpeg",
    "image/jpeg",
    "image/pjp",
    "image/jpg",
  ];

  function handleSubmit(event) {
    event.preventDefault();
    let validFile = fileTypes.includes(fileInput.current.files[0].type);
    if (!validFile) {
      alert(
        `Selected file - ${fileInput.current.files[0].name} is not an image file`
      );
    } else {
      setImageURL(URL.createObjectURL(fileInput.current.files[0]));
    }
  }

  return (
    <Form onSubmit={handleSubmit}>
      <Form.Group>
        <Form.Label>
          Optionally insert header image:
          <Form.Control
            type="file"
            ref={fileInput}
            size="sm"
            accept="image/png, image/jpeg"
            required
          />
        </Form.Label>
        <br />
        <Button type="submit">Insert image</Button>{" "}
        {imageURL !== "" && (
          <Button variant="danger" onClick={() => setImageURL("")}>
            Remove image
          </Button>
        )}
      </Form.Group>
    </Form>
  );
}

const Worksheet = () => {
  const [workSheet, setWorkSheet] = useStickyState([]);
  const [imageURL, setImageURL] = useState("");

  // print out
  const componentRef = useRef();
  const [ansKeyState, setAnsKeyState] = useState(true);
  const [closeTaskState, setCloseTaskState] = useState(true);
  const [closeDirState, setCloseDirState] = useState(true);
  const [arrowState, setArrowState] = useState(true);
  const [pageBreakPrint, setPageBreakPrint] = useState(true);

  const sortedWorkSheet = [...workSheet];
  sortedWorkSheet.sort((a, b) => a.order - b.order);
  const orders = sortedWorkSheet.map((item) => item.order);
  const minOrder = Math.min(...orders);
  const maxOrder = Math.max(...orders);

  const handleBeforeGetContent = (ansKey) => {
    setAnsKeyState(ansKey);
    setCloseTaskState(false);
    setCloseDirState(false);
    setArrowState(false);
    setPageBreakPrint(false);
    return Promise.resolve();
  };
  const handlePrintStudent = useReactToPrint({
    content: () => componentRef.current,
    onBeforeGetContent: () => handleBeforeGetContent(false),
    onAfterPrint: () => {
      setAnsKeyState(true);
      setCloseTaskState(true);
      setCloseDirState(true);
      setArrowState(true);
      setPageBreakPrint(true);
    },
  });
  const handlePrintTeacher = useReactToPrint({
    content: () => componentRef.current,
    onBeforeGetContent: () => handleBeforeGetContent(true),
    onAfterPrint: () => {
      setCloseTaskState(true);
      setCloseDirState(true);
    },
  });

  useDocumentTitle(
    "Folder",
    "Folder collects tests from all apps for printing"
  );

  const handleTaskClose = (order) => {
    const filteredArray = sortedWorkSheet.filter(
      (data) => data.order !== order
    );
    setWorkSheet(filteredArray);
  };

  const handleDirClose = (order) => {
    let newDataArray = sortedWorkSheet.map((data) => {
      if (data.order === order) {
        data.dir_text = null;
      }
      return data;
    });
    setWorkSheet(newDataArray);
  };

  const handleUp = (curOrder) => {
    let curIndex = orders.indexOf(curOrder);
    let prevOrder = orders[curIndex - 1];

    let newWorkSheet = sortedWorkSheet.map((item, i) => {
      if (item.order === curOrder) {
        let newItem = { ...item, order: prevOrder };
        return newItem;
      } else if (item.order === prevOrder) {
        let newItem = { ...item, order: curOrder };
        return newItem;
      } else {
        return item;
      }
    });

    setWorkSheet(newWorkSheet);
  };

  const handleDown = (curOrder) => {
    let curIndex = orders.indexOf(curOrder);
    let nextOrder = orders[curIndex + 1];

    let newWorkSheet = sortedWorkSheet.map((item, i) => {
      if (item.order === curOrder) {
        let newItem = { ...item, order: nextOrder };
        return newItem;
      } else if (item.order === nextOrder) {
        let newItem = { ...item, order: curOrder };
        return newItem;
      } else {
        return item;
      }
    });

    setWorkSheet(newWorkSheet);
  };

  const handlePageBreak = (order) => {
    let newDataArray = sortedWorkSheet.map((data) => {
      if (data.order === order) {
        data.pageBreak = !data.pageBreak;
      }
      return data;
    });
    setWorkSheet(newDataArray);
  };

  const FolderKeyItem = ({ taskItem, index }) => {
    let folderChild;
    let arrowStyleSize = { fontSize: "1.5rem" };
    let keyName = taskItem.keyName;
    let pageBreak = taskItem.pageBreak;

    switch (keyName) {
      case "dictionariesMultiple":
        folderChild = (data_) => <TheTaskDictMultiple data={data_} />;
        break;
      case "clozeTest":
        folderChild = (data_) => (
          <TheTaskClozeTest data={data_} ansKeyState={ansKeyState} />
        );
        break;
      case "multipleChoice":
        folderChild = (data_) => (
          <TheTaskMultipleChoice data={data_} ansKeyState={ansKeyState} />
        );
        break;
      case "wordShuffler":
        folderChild = (data_) => (
          <TheTaskWordShuffler data={data_} ansKeyState={ansKeyState} />
        );
        break;
      case "suffix-matcher":
        folderChild = (data_) => (
          <TheTaskSufPref
            data={data_}
            sufprefWord="suffix"
            ansKeyState={ansKeyState}
          />
        );
        break;
      case "prefix-matcher":
        folderChild = (data_) => (
          <TheTaskSufPref
            data={data_}
            sufprefWord="prefix"
            ansKeyState={ansKeyState}
          />
        );
        break;
      case "frequentWords":
        folderChild = (data_) => <TheTaskFrequentWord data={data_} />;
        break;
      case "matchingTask":
        folderChild = (data_) => (
          <TheTaskMatching data={data_} ansKeyState={ansKeyState} />
        );
        break;
      case "synonyms-basic":
        folderChild = (data_) => (
          <TheTaskSynonyms data={data_} ansKeyState={ansKeyState} />
        );
        break;
      case "synonyms-advanced":
        folderChild = (data_) => (
          <TheTaskSynonyms data={data_} ansKeyState={ansKeyState} />
        );
        break;
      case "antonyms":
        folderChild = (data_) => (
          <TheTaskSynonyms data={data_} ansKeyState={ansKeyState} />
        );
        break;
      case "wordHighlight":
        folderChild = (data_) => <TheTaskHighlight data={data_} />;
        break;
      case "qmaker":
        folderChild = (data_) => <TheTaskQuestionMaker data={data_} />;
        break;
      default:
        break;
    }

    return (
      <Container>
        <Row>
          {arrowState && (
            <Col className="col-1">
              <ButtonGroup>
                <Button
                  variant="link"
                  disabled={minOrder === taskItem.order}
                  onClick={() => handleUp(taskItem.order)}
                >
                  <FontAwesomeIcon icon={faArrowUp} style={arrowStyleSize} />
                </Button>
                <Button
                  variant="link"
                  disabled={maxOrder === taskItem.order}
                  onClick={() => handleDown(taskItem.order)}
                >
                  <FontAwesomeIcon icon={faArrowDown} style={arrowStyleSize} />
                </Button>
              </ButtonGroup>
            </Col>
          )}
        </Row>
        <Row>
          <FolderItem
            index={index}
            test_={taskItem}
            closeTaskState={closeTaskState}
            handleTaskClose={handleTaskClose}
            closeDirState={closeDirState}
            handleDirClose={handleDirClose}
            keyName={keyName}
          >
            {folderChild(taskItem)}
          </FolderItem>
        </Row>
        {/* no break before the footnotes */}
        {index !== sortedWorkSheet.length - 1 && (
          <>
            {pageBreakPrint && (
              <Button
                variant="link"
                onClick={() => handlePageBreak(taskItem.order)}
              >
                <h6 style={{ color: pageBreak ? "#0d6efd" : "grey" }}>
                  {pageBreak
                    ? "-------Page Break-------"
                    : "-------No Page Break-------"}
                </h6>
              </Button>
            )}
            {pageBreak && <p style={{ pageBreakAfter: "always" }}>&nbsp;</p>}
          </>
        )}
      </Container>
    );
  };

  const folderBigItems = sortedWorkSheet.map((taskItem, index) => (
    <FolderKeyItem taskItem={taskItem} index={index} key={index} />
  ));

  // need forward ref for printing
  const TheWorksheet = React.forwardRef((_, ref) => {
    let footStyleSize = { fontSize: "0.75rem" };
    return (
      <Container ref={ref}>
        {imageURL !== "" && (
          <Row className="justify-content-center">
            <Col xs={6} md={4}>
              <Image src={imageURL} thumbnail fluid />
            </Col>
          </Row>
        )}

        {folderBigItems}

        <Row>&nbsp;</Row>
        <Row>&nbsp;</Row>
        <Row className="end">
          <Col>
            <p className="text-start" style={footStyleSize}>
              Copyright © 2020-2022 EnglishApps. All Rights Reserved.
            </p>
          </Col>
          <Col>
            <p className="text-end" style={footStyleSize}>
              Created for ESL/ELL learners with{" "}
              <a href="http://englishapps.ca">www.englishapps.ca</a>
            </p>
          </Col>
        </Row>
      </Container>
    );
  });

  return (
    <PageContainer title="Here you can find the tasks you have created.">
      {folderBigItems.length > 0 && (
        <>
          <br />
          <Button onClick={() => handlePrintTeacher()} className="mr-2">
            Print instructor copy
          </Button>{" "}
          <Button onClick={() => handlePrintStudent()} className="mr-2">
            Print student copy
          </Button>{" "}
          <Button variant="danger" onClick={() => setWorkSheet([])}>
            Reset folder
          </Button>
          <FileInput imageURL={imageURL} setImageURL={setImageURL} />
          <br />
          <br />
          <TheWorksheet ref={componentRef} />
        </>
      )}
    </PageContainer>
  );
};

export default Worksheet;
