import { memo, useState } from "react";
import Button from "react-bootstrap/Button";
import Container from "react-bootstrap/Container";
import Form from "react-bootstrap/Form";
import LoadingOrError from "../components/LoadingOrError";
import PageContainer from "../components/PageContainer";
import SaveToFolder from "../components/SaveToFolder";
import TheTaskQuestionMaker from "../components/TheTaskQuestionMaker";
import { getWordCount } from "../helpers/getWordCount";
import saveToFolder from "../helpers/saveToFolder";
import { useDocumentTitle } from "../helpers/useDocumentTitle";
import { useFetch } from "../helpers/useFetch";
import { useStickyState } from "../helpers/useStickyState";

const keyName = "qmaker";

const QuestionMaker = () => {
  const pageDesc =
    "Generates Text with Pasted Text and Critical Thinking Items";
  const [sketchText, setSketchText] = useState([]);
  const [workSheet, setWorkSheet] = useStickyState([]);
  const { loading, data, error } = useFetch("/items/question-maker");
  const [selectCategory, setSelectCategory] = useState();
  const [selectSubCategory, setSelectSubCategory] = useState();
  const [selectQuestion, setSelectQuestion] = useState();
  const [textArea, setTextArea] = useState();
  const [directionText, setDirectionText] = useState(
    "Read each excerpt from the article [ _____ ] and answer the " +
      "question below it."
  );
  const [saveFolder, setSaveFolder] = useState(false);
  const [showFolder, setShowFolder] = useState(false);

  const wordCount = getWordCount(textArea);

  useDocumentTitle("Critical Thinking", pageDesc);

  let dataForWorksheet = {
    exercise: sketchText,
    dir_text: directionText,
    keyName: keyName,
  };

  const handleInputChange = (event) => {
    const target = event.target;
    const id = target.id;

    if (target.value && id === "selectCategory") {
      let category = [target.value];
      setSelectCategory(category);
      let subCategory = Object.keys(data[category])[0];
      setSelectSubCategory(subCategory);
      let question = data[category][subCategory][0];
      setSelectQuestion(question);
    } else if (id === "selectSubCategory") {
      let category = selectCategory;
      let subCategory = target.value;
      setSelectSubCategory(subCategory);
      let question = data[category][subCategory][0];
      setSelectQuestion(question);
    } else if (id === "selectQuestion") {
      setSelectQuestion(target.value);
    } else if (id === "textarea") {
      setTextArea(target.value);
    }
    if (id === "directionText") setDirectionText(target.value);
  };

  if (loading || error)
    return <LoadingOrError loading_={loading} error_={error} />;

  const handleClick = (event) => {
    const target = event.target;
    const id = target.id;
    if (id === "removeItem" && sketchText) {
      setSketchText(sketchText.slice(0, -1));
    } else if (id === "insertQuestion" && selectQuestion) {
      setSketchText(sketchText.concat(selectQuestion));
    }
  };

  function handleSubmit(event) {
    setSketchText(sketchText.concat(textArea));
    event.preventDefault();
  }

  const DoSubCategory = () => {
    try {
      const subcategories = Object.keys(data[selectCategory]);
      return (
        <>
          <Form.Label htmlFor="selectSubCategory">
            Select question sub-category
          </Form.Label>
          <Form.Select
            id="selectSubCategory"
            value={selectSubCategory}
            onChange={handleInputChange}
          >
            {subcategories.map((item, i) => (
              <option value={item} key={i}>
                {item}
              </option>
            ))}
          </Form.Select>
        </>
      );
    } catch {
      return null;
    }
  };

  const DoQuestion = () => {
    try {
      const questions = data[selectCategory][selectSubCategory];
      return (
        <>
          <Form.Label htmlFor="selectSubCategory">Select question</Form.Label>
          <Form.Select
            id="selectQuestion"
            value={selectQuestion}
            onChange={handleInputChange}
          >
            {questions.map((item, i) => (
              <option value={item} key={i}>
                {item}
              </option>
            ))}
          </Form.Select>
        </>
      );
    } catch {
      return null;
    }
  };

  return (
    <PageContainer title={pageDesc}>
      <Form onSubmit={handleSubmit}>
        <Form.Group>
          <Form.Label htmlFor="textarea">Enter paragraph below.</Form.Label>
          <Form.Control
            as="textarea"
            id="textarea"
            rows="3"
            value={textArea}
            onChange={handleInputChange}
            required
          />
          <Form.Text className="text-muted">Word count: {wordCount}</Form.Text>
        </Form.Group>
        <Button variant="primary" type="Submit" id="insertText">
          Insert the text
        </Button>
        <Form.Group>
          <Form.Label htmlFor="selectCategory">
            Select question category
          </Form.Label>
          <Form.Select
            id="selectCategory"
            value={selectCategory}
            onChange={handleInputChange}
          >
            {["", ...Object.keys(data)].map((item, i) => (
              <option value={item} key={i}>
                {item}
              </option>
            ))}
          </Form.Select>

          <DoSubCategory />

          <DoQuestion />
        </Form.Group>
        <Button variant="primary" id="insertQuestion" onClick={handleClick}>
          Insert the question
        </Button>
        <br />
        <br />
        <Button variant="secondary" id="removeItem" onClick={handleClick}>
          Remove the last item
        </Button>
        <br />
        <br />
      </Form>

      <Form
        onSubmit={(e) =>
          saveToFolder(
            e,
            workSheet,
            dataForWorksheet,
            setWorkSheet,
            setShowFolder,
            setSaveFolder
          )
        }
      >
        <Form.Group>
          <Form.Label htmlFor="directionText">
            Task directions (edit as needed).
          </Form.Label>
          <Form.Control
            as="textarea"
            id="directionText"
            rows="1"
            value={directionText}
            onChange={handleInputChange}
            required
          />
        </Form.Group>
        <Container className="anskeycontainer">
          <p>
            <b>Directions:</b> {directionText}
          </p>
        </Container>
        <TheTaskQuestionMaker data={dataForWorksheet} />
        <SaveToFolder saveFolder={saveFolder} showFolder={showFolder} />
      </Form>
    </PageContainer>
  );
};

export default memo(QuestionMaker);
