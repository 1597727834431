import { API } from "aws-amplify";
import { useCallback, useMemo, useState } from "react";
import { Button, Form } from "react-bootstrap";
import { Link } from "react-router-dom";
import ClearContent from "../components/ClearContent";
import Directions from "../components/Directions";
import HelpTip from "../components/HelpTip";
import LoadingOrError from "../components/LoadingOrError";
import PageContainer from "../components/PageContainer";
import SaveToFolder from "../components/SaveToFolder";
import TheTaskDictMultiple from "../components/TheTaskDictMultiple";
import { getWordCount } from "../helpers/getWordCount";
import saveToFolder from "../helpers/saveToFolder";
import { useDocumentTitle } from "../helpers/useDocumentTitle";
import { useStickyState } from "../helpers/useStickyState";

const keyName = "dictionariesMultiple";

const helpData = {
  mainTitle: "Vocabulary",
  title1: "What is a Vocabulary?",
  text1: `It enables you to lookup definitions of multiple words at once.`,
  title2: "What is the purpose of a Vocabulary?",
  text2: `It is useful for learning new words.`,
  title3: "How do I use the Vocabulary?",
  text3: (
    <ol>
      <li>
        Paste a paragraph/sentence of an appropriate length and complexity level
        for your students in the text box [Enter your text here]. You can expand
        the text box by dragging with the mouse on the bottom, right corner.
      </li>
      <li>
        Specify the words to be searched by typing them (comma-separated) in the
        text box. Note that lower case is enforced, even if you type with upper
        case.
      </li>
      <li>
        Click on <strong>Create task</strong> button. If task is not generated
        then the words do not exist in the current dictionary, try with another
        one.
      </li>
      <li>
        Enter task directions and click
        <strong> Save to folder</strong>, go to your
        <strong>
          {" "}
          <Link to="/folder">Folder</Link>{" "}
        </strong>
        to print out your worksheet.
      </li>
      <li>
        Note that inputs from your previous session are retained if you work on
        the same machine and browser
      </li>
    </ol>
  ),
  keyName: keyName,
};

const myParams = {
  directions: [
    `Read the passage below and make sentences with the vocabulary provided 
    below it.`,
  ],
};

const DictionariesMultiple = () => {
  const [workSheet, setWorkSheet] = useStickyState([]);
  const [formStore, setFormStore] = useStickyState({}, "eng-apps-" + keyName);
  const [data, setData] = useState({
    keyName: keyName,
    defs: [],
    paragraph: formStore.textArea,
    dir_text: myParams.directions[0],
  });
  const [isSending, setIsSending] = useState(false);
  const [error, setError] = useState();
  const [textValue, setTextValue] = useState(formStore.textArea);
  const [customWordsText, setCustomWordsText] = useState(formStore.customWords);
  const [directionText, setDirectionText] = useState(formStore.dir_text);
  const [saveFolder, setSaveFolder] = useState(false);
  const [showFolder, setShowFolder] = useState(false);
  const [ownCheck, setOwnCheck] = useState(false);
  const [dirSelect, setDirSelect] = useState(myParams.directions[0]);

  // commented code-01

  const dictApiSelect = "Apache Open Office Thesaurus";

  const wordCount = getWordCount(textValue);

  const handleDefClose = (i, e) => {
    let new_defs = data.defs.filter((_, index) => index !== i);
    setData({ ...data, defs: new_defs });
  };

  // commented code-02

  const handleInputChange = (event) => {
    const target = event.target;
    const id = target.id;

    if (id === "textarea") {
      setTextValue(target.value);
      setData({ ...data, paragraph: target.value });
      setFormStore({ ...formStore, textArea: target.value });
    } else if (id === "directionText") {
      setData({ ...data, dir_text: target.value });
      setFormStore({
        ...formStore,
        dir_text: target.value,
        myOwnDir: target.value,
      });
      setDirectionText(target.value);
    } else if (id === "ownCheck") {
      // if currently checked then user wants to un-check, hence we need to set
      // to predefined values
      let newDirText = ownCheck ? myParams.directions[0] : directionText;
      setOwnCheck(!ownCheck);
      setFormStore({
        ...formStore,
        dir_text: newDirText,
      });
      setData({ ...data, dir_text: newDirText });
    } else if (id === "dirSelect") {
      setDirSelect(target.value);
      setData({ ...data, dir_text: target.value });
    } else if (id === "customWords") {
      let theValue = target.value.toLowerCase();

      setFormStore({ ...formStore, customWords: theValue });
      setCustomWordsText(theValue);
    }
  };

  useDocumentTitle(helpData.mainTitle, helpData.text1);

  const myInit = useMemo(
    () => ({
      headers: { "Content-Type": "application/json" },
      body: {
        dictionary: dictApiSelect,
        dicFilter: customWordsText,
      },
    }),
    [customWordsText, dictApiSelect]
  );

  const memoizedCallback = useCallback(
    (event) => {
      // need to disable submit buttons post action
      event.preventDefault();
      // need to prevent more than one request sending
      if (isSending) return;
      setIsSending(true);
      API.post("apienglishreactamplify", "/items/dictionaries-multiple", myInit)
        .then((response) => {
          setData({ ...data, defs: response });
        })
        .then(() => setIsSending(false))
        .catch(setError);
    },
    [isSending, myInit, data]
  );

  return (
    <PageContainer title={helpData.mainTitle}>
      <HelpTip dictIn={helpData} />
      <Form onSubmit={memoizedCallback}>
        <Form.Group>
          <Form.Label htmlFor="textarea">
            1. Paste or type a paragraph/sentence below.
          </Form.Label>
          {textValue && textValue.length > 0 && (
            <ClearContent
              setBox={setTextValue}
              formStore={formStore}
              setForm={setFormStore}
              formKey="textArea"
            />
          )}
          <Form.Control
            as="textarea"
            id="textarea"
            rows="3"
            value={textValue}
            onChange={handleInputChange}
            required
            placeholder="Enter your text here."
          />
          <Form.Text className="text-muted">Word count: {wordCount}</Form.Text>
        </Form.Group>
        <Form.Group>
          {/*  commented code-03 */}

          <Form.Label htmlFor="customWords">
            2. Type comma-separated words (lower case is enforced) to be located
            in the dictionary.
          </Form.Label>
          {customWordsText && customWordsText.length > 0 && (
            <ClearContent
              setBox={setCustomWordsText}
              formStore={formStore}
              setForm={setFormStore}
              formKey="customWords"
            />
          )}
          <Form.Control
            as="textarea"
            id="customWords"
            rows="1"
            value={customWordsText}
            onChange={handleInputChange}
            required
            placeholder="Enter comma separated words."
          />
        </Form.Group>
        <br />
        <Button type="submit" disabled={isSending} className="mb-3">
          Create task
        </Button>{" "}
        {(isSending || error) && (
          <LoadingOrError loading_={isSending} error_={error} />
        )}
      </Form>
      {data.defs.length > 0 && (
        <Form
          onSubmit={(e) =>
            saveToFolder(
              e,
              workSheet,
              data,
              setWorkSheet,
              setShowFolder,
              setSaveFolder
            )
          }
        >
          <Directions
            dirSelect={dirSelect}
            directionText={directionText}
            formStore={formStore}
            handleInputChange={handleInputChange}
            myParamsDirections={myParams.directions}
            ownCheck={ownCheck}
            setDirectionText={setDirectionText}
            setFormStore={setFormStore}
          />
          <TheTaskDictMultiple
            data={data}
            handleDefClose={handleDefClose}
            isMain={true}
          />
          <SaveToFolder saveFolder={saveFolder} showFolder={showFolder} />
        </Form>
      )}
    </PageContainer>
  );
};

export default DictionariesMultiple;

// commented code-01
// const [dictSelect, setDictSelect] = useState("Dictionary 3");
// const [dictApiSelect, setDictApiSelect] = useState(
//   "Apache Open Office Thesaurus"
// );

// commented code-02
// const handleDictSelect = (e) => {
//   switch (e.target.value) {
//     case "Dictionary 3":
//       setDictApiSelect("Apache Open Office Thesaurus");
//       break;
//     case "Dictionary 4":
//       setDictApiSelect("Moby Thesaurus");
//       break;
//     default:
//       setDictApiSelect(e.target.value);
//       break;
//   }
//   setDictSelect(e.target.value);
// };

// commented code-03
// {/* <Form.Label htmlFor="dictSelect">2. Select Dictionary.</Form.Label>
// <Form.Select
//   value={dictSelect}
//   onChange={handleDictSelect}
//   id="dictSelect"
// >
//   <option>Dictionary 1</option>
//   <option>Dictionary 2</option>
//   <option>Dictionary 3</option>
//   <option>Dictionary 4</option>
// </Form.Select>
// <br /> */}
