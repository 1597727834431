import { API } from "aws-amplify";
import { useCallback, useMemo, useState } from "react";
import { Button, Form } from "react-bootstrap";
import { Link } from "react-router-dom";
import ClearContent from "../components/ClearContent";
import Directions from "../components/Directions";
import HelpTip from "../components/HelpTip";
import LoadingOrError from "../components/LoadingOrError";
import PageContainer from "../components/PageContainer";
import SaveToFolder from "../components/SaveToFolder";
import TheTaskMatching from "../components/TheTaskMatching";
import { getWordCount } from "../helpers/getWordCount";
import saveToFolder from "../helpers/saveToFolder";
import { useDocumentTitle } from "../helpers/useDocumentTitle";
import { useStickyState } from "../helpers/useStickyState";

const keyName = "matchingTask";

const helpData = {
  mainTitle: "Matching Task",
  title1: "What is a Matching Task?",
  text1: `The Matching Task splits sentences in any passage into two parts.
  Learners’ task is to match the parts of each sentence relying on their
  logical thinking and English language proficiency.`,
  title2: "What is the purpose of a Matching Task?",
  text2: `A Matching Task is used for enhancing reading comprehension in a
  second/foreign language and retention of key ideas of a reading passage.
  Learners will also improve their sentence formation and written/spoken
  accuracy.`,
  title3: "How do I use the Matching Task app?",
  text3: (
    <ol>
      <li>
        Paste a paragraph/sentence of an appropriate length and complexity level
        for your students in the text box [Enter your text here]. You can expand
        the text box by dragging with the mouse on the bottom, right corner.
      </li>
      <li>
        Specify the word at which all sentences in the passage should be split
        by choosing a number, e.g., 3 means all sentences will be split after
        the third word in a sentence.
      </li>
      <li>
        Click on <strong>Create task</strong> button.
      </li>
      <li>
        Enter task directions and click
        <strong> Save to folder</strong>, go to your
        <strong>
          {" "}
          <Link to="/folder">Folder</Link>{" "}
        </strong>{" "}
        to print out your worksheet.
      </li>
      <li>
        Note that inputs from your previous session are retained if you work on
        the same machine and browser
      </li>
    </ol>
  ),
  keyName: keyName,
};

const myParams = {
  directions: [`Match the sentence parts.`],
};

const Matching = ({ signOut }) => {
  const [workSheet, setWorkSheet] = useStickyState([]);
  const [formStore, setFormStore] = useStickyState({}, "eng-apps-" + keyName);
  const [data, setData] = useState({
    keyName: keyName,
    defs: null,
    dir_text: myParams.directions[0],
  });
  const [nthValue, setNthValue] = useState(3);
  const [isSending, setIsSending] = useState(false);
  const [error, setError] = useState();
  const [textValue, setTextValue] = useState(formStore.textArea);
  const [directionText, setDirectionText] = useState(formStore.myOwnDir);
  const [saveFolder, setSaveFolder] = useState(false);
  const [showFolder, setShowFolder] = useState(false);
  const [ownCheck, setOwnCheck] = useState(false);
  const [dirSelect, setDirSelect] = useState(myParams.directions[0]);

  const handleInputChange = (event) => {
    const target = event.target;
    const id = target.id;

    if (id === "textarea") {
      setTextValue(target.value);
      setFormStore({ ...formStore, textArea: target.value });
    } else if (id === "directionText") {
      setData({ ...data, dir_text: target.value });
      setFormStore({
        ...formStore,
        dir_text: target.value,
        myOwnDir: target.value,
      });
      setDirectionText(target.value);
    } else if (id === "ownCheck") {
      // if currently checked then user wants to un-check, hence we need to set
      // to predefined values
      let newDirText = ownCheck ? myParams.directions[0] : directionText;
      setOwnCheck(!ownCheck);
      setFormStore({
        ...formStore,
        dir_text: newDirText,
      });
      setData({ ...data, dir_text: newDirText });
    } else if (id === "dirSelect") {
      setDirSelect(target.value);
      setData({ ...data, dir_text: target.value });
    } else if (id === "nthValue") setNthValue(target.value);
  };

  const myInit = useMemo(
    () => ({
      headers: { "Content-Type": "application/json" },
      body: {
        textarea: textValue,
        nthword: nthValue,
        custom_words: null,
      },
    }),
    [textValue, nthValue]
  );

  const memoizedCallback = useCallback(
    (event) => {
      // need to disable submit buttons post action
      event.preventDefault();
      // need to prevent more than one request sending
      if (isSending) return;
      setIsSending(true);
      API.post("apienglishreactamplify", "/items/matching", myInit)
        .then((response) => {
          setData({ ...data, defs: response });
        })
        .then(() => setIsSending(false))
        .catch(setError);
    },
    [isSending, myInit, data]
  );

  useDocumentTitle(helpData.mainTitle, helpData.text1);

  const wordCount = getWordCount(textValue);

  return (
    <PageContainer title={helpData.mainTitle} signOut={signOut}>
      <HelpTip dictIn={helpData} />
      <Form onSubmit={memoizedCallback}>
        <Form.Group>
          <Form.Label htmlFor="textarea">
            1. Paste or type a paragraph/sentence below.
          </Form.Label>
          {textValue && textValue.length > 0 && (
            <ClearContent
              setBox={setTextValue}
              formStore={formStore}
              setForm={setFormStore}
              formKey="textArea"
            />
          )}
          <Form.Control
            as="textarea"
            id="textarea"
            rows="3"
            value={textValue}
            onChange={handleInputChange}
            required
            placeholder="Enter your text here."
          />
          <Form.Text className="text-muted">Word count: {wordCount}</Form.Text>
        </Form.Group>
        <Form.Group>
          <Form.Label htmlFor="nthValue">
            2. Specify the word at which to split sentences by choosing a
            number.
          </Form.Label>
          <Form.Control
            as="input"
            type="number"
            id="nthValue"
            name="nthValue"
            min="1"
            value={nthValue}
            onChange={handleInputChange}
            required
          />
        </Form.Group>
        <br />
        <Button type="submit" disabled={isSending} className="mb-3">
          Create task
        </Button>{" "}
        {(isSending || error) && (
          <LoadingOrError loading_={isSending} error_={error} />
        )}
      </Form>
      {data.defs && (
        <Form
          onSubmit={(e) =>
            saveToFolder(
              e,
              workSheet,
              data,
              setWorkSheet,
              setShowFolder,
              setSaveFolder
            )
          }
        >
          <Directions
            dirSelect={dirSelect}
            directionText={directionText}
            formStore={formStore}
            handleInputChange={handleInputChange}
            myParamsDirections={myParams.directions}
            ownCheck={ownCheck}
            setDirectionText={setDirectionText}
            setFormStore={setFormStore}
          />
          <TheTaskMatching data={data} />
          <SaveToFolder saveFolder={saveFolder} showFolder={showFolder} />
        </Form>
      )}
    </PageContainer>
  );
};

export default Matching;
