import {
  faAward,
  faHeadset,
  faUniversity,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { memo } from "react";
import { Button, Card, Col, Container, Row } from "react-bootstrap";
import { Carousel } from "react-responsive-carousel";
import { useNavigate } from "react-router-dom";
import homeCarousel from "../assets/images/carousel/00-home.jpg";
import dictionariesCarousel from "../assets/images/carousel/01-dict.jpg";
import clozeTestCarousel from "../assets/images/carousel/02-cloze.jpg";
import multipleChoiceCarousel from "../assets/images/carousel/03-multiple.jpg";
import sentenceCarousel from "../assets/images/carousel/04-sentence.jpg";
import suffixCarousel from "../assets/images/carousel/05-suffix.jpg";
import frequentCarousel from "../assets/images/carousel/06-frequent.jpg";
import matchingCarousel from "../assets/images/carousel/07-matching.jpg";
import synonymsCarousel from "../assets/images/carousel/08-synonyms.jpg";
import highlightCarousel from "../assets/images/carousel/09-highlight.jpg";
import { useDocumentTitle } from "../helpers/useDocumentTitle";

const Home = () => {
  useDocumentTitle("English Apps");

  return (
    <>
      <MyCarousel />
      <MyFeatures />
      <MyMission />
      <hr />
      <Container className="text-center">
        <Button href="/contact-us">Leave your feedback</Button>
      </Container>
      <hr />
    </>
  );
};

// need to redirect carousel clicks to pages
const MyCarousel = () => {
  let navigate = useNavigate();

  const handleClick = (e) => {
    switch (e) {
      case 0:
        navigate("/");
        break;
      case 1:
        navigate("/dictionaries");
        break;
      case 2:
        navigate("/cloze-test");
        break;
      case 3:
        navigate("/multiple-choice");
        break;
      case 4:
        navigate("/word-shuffler");
        break;
      case 5:
        navigate("/sufpref");
        break;
      case 6:
        navigate("/frequent-words");
        break;
      case 7:
        navigate("/matching");
        break;
      case 8:
        navigate("/synant");
        break;
      case 9:
        navigate("/highlight");
        break;
      default:
        break;
    }
  };

  return (
    <Container>
      <Carousel autoPlay onClickItem={handleClick}>
        <div>
          <img src={homeCarousel} alt="Home" />
          <p className="legend my-carousel">
            Use EnglishApps to create text-based practice tasks & worksheets for
            English learners.
          </p>
        </div>
        <div>
          <img src={dictionariesCarousel} alt="Dictionary" />
          <p className="legend my-carousel">
            Use EnglishApps to create text-based practice tasks & worksheets for
            English learners.
          </p>
        </div>
        <div>
          <img src={clozeTestCarousel} alt="Cloze Test" />
          <p className="legend my-carousel">
            Create Cloze Tests to assess learners' comprehension of English
            texts within seconds.
          </p>
        </div>
        <div>
          <img src={multipleChoiceCarousel} alt="Multiple Choice" />
          <p className="legend my-carousel">
            Create Multiple Choice quizzes to assess learners' knowledge of
            English vocabulary within seconds.
          </p>
        </div>
        <div>
          <img src={sentenceCarousel} alt="Sentence Shuffler" />
          <p className="legend my-carousel">
            Use Sentence Shuffler to rearrange the words when practicing word
            order of English sentences.
          </p>
        </div>
        <div>
          <img src={suffixCarousel} alt="Suffix & Prefix Matching" />
          <p className="legend my-carousel">
            Create Suffix & Prefix Matching worksheets to help English learners
            of all levels understand word formation.
          </p>
        </div>
        <div>
          <img src={frequentCarousel} alt="Frequent Word Highlighter" />
          <p className="legend my-carousel">
            Use Frequent Word Highlighter to create worksheets that support
            learners' acquisition of commonly used English words.
          </p>
        </div>
        <div>
          <img src={matchingCarousel} alt="Matching Tasks" />
          <p className="legend my-carousel">
            Create Matching Tasks to practice and assess learners' knowledge of
            various topics and the English language.
          </p>
        </div>
        <div>
          <img src={synonymsCarousel} alt="Synonyms & Antonyms Matching" />
          <p className="legend my-carousel">
            Generate Synonyms & Antonyms Matching worksheets for developing
            English learners' vocabulary.
          </p>
        </div>
        <div>
          <img src={highlightCarousel} alt="Word Highlighter" />
          <p className="legend my-carousel">
            Use Word Highlighter to highlight key ideas and vocabulary for
            learners to practice.
          </p>
        </div>
      </Carousel>
    </Container>
  );
};

const MyFeatures = () => (
  <Container>
    <Row>
      <Col>
        <Card>
          <FontAwesomeIcon
            icon={faAward}
            size="3x"
            pull="left"
            className="my-landing-media"
          />
          <Card.Body>
            <h5 className="my-landing-media">User-friendly applications</h5>
            <p>
              All the necessary features for creating engaging worksheets for
              English learners.
            </p>
          </Card.Body>
        </Card>
      </Col>
      <Col>
        <Card>
          <FontAwesomeIcon
            icon={faUniversity}
            size="3x"
            pull="left"
            className="my-landing-media"
          />
          <Card.Body>
            <h5 className="my-landing-media">Educational technology</h5>
            <p>
              Created in partnership with inspirational teachers and expert
              developers.
            </p>
          </Card.Body>
        </Card>
      </Col>
      <Col>
        <Card>
          <FontAwesomeIcon
            icon={faHeadset}
            size="3x"
            pull="left"
            className="my-landing-media"
          />
          <Card.Body>
            <h5 className="my-landing-media">24/7 Technical support</h5>
            <p>
              Our technical support specialists provide an unparalleled customer
              service.
            </p>
          </Card.Body>
        </Card>
      </Col>
    </Row>
  </Container>
);

const MyMission = () => (
  <Container>
    <Card className="text-center">
      <Card.Header>Our Promise</Card.Header>
      <Card.Body>
        <Card.Title>
          With English Apps, creating content-based practice worksheets for
          English learners will take a few clicks.
        </Card.Title>
      </Card.Body>
    </Card>
  </Container>
);

export default memo(Home);
