import { useEffect } from "react";
import { titleCase } from "./titleCase";

export const useDocumentTitle = (pageTitle, pageDesc = null) => {
  let newPageTitle1 = pageTitle.replace("-", " ");
  let newPageTitle2 = titleCase(newPageTitle1);
  let newPageDesc = pageDesc ? pageDesc.replace(/\s+/g, " ") : pageDesc;

  useEffect(() => {
    document.title = newPageTitle2;
    if (newPageDesc) {
      document.getElementsByTagName("meta")[
        "description"
      ].content = newPageDesc;
    }
  }, [newPageTitle2, newPageDesc]);

  return newPageTitle2;
};
