import { Button, Alert, Card, Row, Col } from "react-bootstrap";
import { useState } from "react";
import { useStickyState } from "../helpers/useStickyState";

const HelpTip = ({ dictIn }) => {
  const [show, setShow] = useState(true);
  const [helpNow, setHelpNow] = useState(false);
  const [helpTip, setHelpTip] = useStickyState({}, "eng-apps-helptip");

  let showHelp = show && (helpTip[dictIn.keyName] !== false || helpNow);

  return (
    <>
      {/* need to show expanded help until user clicks close help first time */}
      <Alert show={showHelp} variant="secondary">
        <Row>
          <Col>
            <Card bg="secondary" text="white">
              <Card.Body>
                <Card.Title>{dictIn.title1}</Card.Title>
                <Card.Text>{dictIn.text1}</Card.Text>
              </Card.Body>
            </Card>
          </Col>
          <Col>
            <Card bg="secondary" text="white">
              <Card.Body>
                <Card.Title>{dictIn.title2}</Card.Title>
                <Card.Text>{dictIn.text2}</Card.Text>
              </Card.Body>
            </Card>
          </Col>
        </Row>
        <Row className="mt-2">
          <Col>
            <Card bg="secondary" text="white">
              <Card.Body>
                <Card.Title>{dictIn.title3}</Card.Title>
                {dictIn.text3}
              </Card.Body>
            </Card>
          </Col>
        </Row>
        <hr />
        <div className="d-flex justify-content-end">
          <Button
            onClick={() => {
              setShow(false);
              setHelpTip({ ...helpTip, [dictIn.keyName]: false });
            }}
            variant="outline-secondary"
          >
            Close help
          </Button>
        </div>
      </Alert>
      {!showHelp && (
        <Button
          onClick={() => {
            setShow(true);
            setHelpNow(true);
          }}
          className="mb-3"
        >
          Show help
        </Button>
      )}
    </>
  );
};

export default HelpTip;
