import { API } from "aws-amplify";
import { useCallback, useEffect, useMemo, useState } from "react";
import { Button, Form } from "react-bootstrap";
import { Link } from "react-router-dom";
import Select, { createFilter } from "react-select";
import ClearContent from "../components/ClearContent";
import Directions from "../components/Directions";
import HelpTip from "../components/HelpTip";
import LoadingOrError from "../components/LoadingOrError";
import PageContainer from "../components/PageContainer";
import SaveToFolder from "../components/SaveToFolder";
import TheTaskMultipleChoice from "../components/TheTaskMultipleChoice";
import { MenuList } from "../components/WindowMenuList";
import { getWordCount } from "../helpers/getWordCount";
import saveToFolder from "../helpers/saveToFolder";
import { useDocumentTitle } from "../helpers/useDocumentTitle";
import { useStickyState } from "../helpers/useStickyState";

const keyName = "multipleChoice";

const helpData = {
  mainTitle: "Multiple Choice Test",
  title1: "What is a Multiple Choice Test?",
  text1: `Multiple Choice Tests are comprised of a “stem” or a question and 4-5
  “alternatives”, that is possible answers. Learners must select the best 
  answer.`,
  title2: "What is the purpose of Multiple Choice Tests?",
  text2: `Multiple-choice tests are essential not only for assessing learners’
  knowledge of English vocabulary but also for improving their learning and
  test taking strategies.`,
  title3: "How do I use the Multiple Choice Test app?",
  text3: (
    <ol>
      <li>
        After you have selected a passage/sentence of an appropriate length and
        complexity level for your students, paste it in the text box [Enter your
        text here]. You can expand the text box by dragging with the mouse on
        the bottom, right corner.
      </li>
      <li>
        Select a word in the dictionary which can also be found in your passage.
        <ul>
          <li>
            For example, “Taking a multiple-choice test shortly after study is
            found to have a positive <u>impact</u> on students’ learning.”
            Select the word <u>impact</u> and click on{" "}
            <strong>Create task </strong>
            button.
          </li>
        </ul>
      </li>
      <li>
        Enter task directions and click
        <strong> Save to folder</strong>, go to your
        <strong>
          {" "}
          <Link to="/folder">Folder</Link>{" "}
        </strong>{" "}
        to print out your worksheet.
      </li>
      <li>
        Note that inputs from your previous session are retained if you work on
        the same machine and browser
      </li>
    </ol>
  ),
  keyName: keyName,
};

const myParams = {
  directions: [
    `Read each sentence and determine which of the provided options includes
  acceptable synonyms for the word below it. (Choose the correct option).`,
  ],
};

const MultipleChoice = ({ signOut }) => {
  const [workSheet, setWorkSheet] = useStickyState([]);
  const [formStore, setFormStore] = useStickyState({}, "eng-apps-" + keyName);
  const [data, setData] = useState({
    keyName: keyName,
    defs: null,
    dir_text: myParams.directions[0],
    text_area: formStore.textArea,
  });
  const [dropDown, setDropDown] = useState({ oxfthes: [], rogthes: [] });
  const [loading, setLoading] = useState(true);
  const [isSending, setIsSending] = useState(false);
  const [error, setError] = useState();
  const [error2, setError2] = useState();
  const [textValue, setTextValue] = useState(formStore.textArea);
  const [directionText, setDirectionText] = useState(formStore.myOwnDir);
  const [saveFolder, setSaveFolder] = useState(false);
  const [showFolder, setShowFolder] = useState(false);
  const [value1, setValue1] = useState({
    value: "abandon (v)",
    label: "abandon (v)",
  });
  const [ownCheck, setOwnCheck] = useState(false);
  const [dirSelect, setDirSelect] = useState(myParams.directions[0]);

  const handleInputChange = (event) => {
    const target = event.target;
    const id = target.id;

    if (id === "textarea") {
      setTextValue(target.value);
      setData({ ...data, text_area: target.value });
      setFormStore({ ...formStore, textArea: target.value });
    } else if (id === "directionText") {
      setData({ ...data, dir_text: target.value });
      setFormStore({
        ...formStore,
        dir_text: target.value,
        myOwnDir: target.value,
      });
      setDirectionText(target.value);
    } else if (id === "ownCheck") {
      // if currently checked then user wants to un-check, hence we need to set
      // to predefined values
      let newDirText = ownCheck ? myParams.directions[0] : directionText;
      setOwnCheck(!ownCheck);
      setFormStore({
        ...formStore,
        dir_text: newDirText,
      });
      setData({ ...data, dir_text: newDirText });
    } else if (id === "dirSelect") {
      setDirSelect(target.value);
      setData({ ...data, dir_text: target.value });
    }
  };

  useEffect(() => {
    API.get("apienglishreactamplify", "/items/multiple-choice", null)
      .then((response) => {
        setDropDown(response);
      })
      .then(() => setLoading(false))
      .catch(setError2);
    // eslint-disable-next-line
  }, []);

  const myInit = useMemo(
    () => ({
      headers: { "Content-Type": "application/json" },
      body: { dict_model: "oxf", def_word: value1["value"] },
    }),
    [value1]
  );

  const memoizedCallback = useCallback(
    (event) => {
      // need to disable submit buttons post action
      event.preventDefault();
      // need to prevent more than one request sending
      if (isSending) return;
      setIsSending(true);
      API.post("apienglishreactamplify", "/items/multiple-choice", myInit)
        .then((response) => {
          setData({ ...data, defs: response });
        })
        .then(() => setIsSending(false))
        .catch(setError);
    },
    [isSending, myInit, data]
  );

  useDocumentTitle(helpData.mainTitle, helpData.text1);

  const wordCount = getWordCount(textValue);

  return (
    <PageContainer title={helpData.mainTitle} signOut={signOut}>
      <HelpTip dictIn={helpData} />
      <Form onSubmit={memoizedCallback}>
        <Form.Group>
          <Form.Label htmlFor="textarea">
            1. Paste or type a sentence below.
          </Form.Label>
          {textValue && textValue.length > 0 && (
            <ClearContent
              setBox={setTextValue}
              formStore={formStore}
              setForm={setFormStore}
              formKey="textArea"
            />
          )}
          <Form.Control
            as="textarea"
            id="textarea"
            rows="3"
            value={textValue}
            onChange={handleInputChange}
            required
            placeholder="Enter your text here."
          />
          <Form.Text className="text-muted">Word count: {wordCount}</Form.Text>
        </Form.Group>
        {loading && <LoadingOrError loading_={loading} error_={error2} />}
        {!loading && (
          <Form.Group>
            <Form.Label htmlFor="value1">
              2. Type the word to be retrieved from the dictionary.
            </Form.Label>
            <Select
              options={dropDown["oxfthes"]}
              components={{ MenuList }}
              onChange={setValue1}
              value={value1}
              filterOption={createFilter({ matchFrom: "start" })}
            />
          </Form.Group>
        )}
        <br />
        <Button type="submit" disabled={isSending} className="mb-3">
          Create task
        </Button>{" "}
        {(isSending || error) && (
          <LoadingOrError loading_={isSending} error_={error} />
        )}
      </Form>
      {data.defs && (
        <Form
          onSubmit={(e) =>
            saveToFolder(
              e,
              workSheet,
              data,
              setWorkSheet,
              setShowFolder,
              setSaveFolder
            )
          }
        >
          <Directions
            dirSelect={dirSelect}
            directionText={directionText}
            formStore={formStore}
            handleInputChange={handleInputChange}
            myParamsDirections={myParams.directions}
            ownCheck={ownCheck}
            setDirectionText={setDirectionText}
            setFormStore={setFormStore}
          />
          <TheTaskMultipleChoice data={data} />
          <SaveToFolder saveFolder={saveFolder} showFolder={showFolder} />
        </Form>
      )}
    </PageContainer>
  );
};

export default MultipleChoice;
