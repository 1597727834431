import { Container } from "react-bootstrap";

export const TheTaskWordShuffler = ({ data, ansKeyState = true }) => {
  return (
    <>
      <Container className="askcontainer">
        {data.defs.map((sentence, i) => (
          <p
            key={i}
            dangerouslySetInnerHTML={{ __html: sentence["exercise"] }}
          />
        ))}
      </Container>
      {ansKeyState && (
        <>
          {" "}
          <code>Answer Key</code>
          {data.defs.map((sentence, i) => (
            <p key={i}>{sentence["answer_key"]}</p>
          ))}
        </>
      )}
    </>
  );
};

export default TheTaskWordShuffler;
