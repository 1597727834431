import { faFolder } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Alert, Breadcrumb, Button } from "react-bootstrap";
import { Link } from "react-router-dom";

const SaveToFolder = ({ saveFolder, showFolder }) => {
  return (
    <>
      <Button type="submit" disabled={saveFolder}>
        Save to folder
      </Button>
      {showFolder && (
        <>
          {" "}
          <Button
            variant="link"
            linkProps={{ to: "/folder" }}
            linkAs={Link}
            as={Breadcrumb.Item}
            active={true}
          >
            <FontAwesomeIcon icon={faFolder} pull="right" size="3x" />
          </Button>
        </>
      )}
      <Alert variant="success" show={saveFolder}>
        Saved to folder
      </Alert>
    </>
  );
};

export default SaveToFolder;
