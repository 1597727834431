import { API } from "aws-amplify";
import { useCallback, useMemo, useState } from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import ClearContent from "../components/ClearContent";
import Directions from "../components/Directions";
import HelpTip from "../components/HelpTip";
import LoadingOrError from "../components/LoadingOrError";
import PageContainer from "../components/PageContainer";
import SaveToFolder from "../components/SaveToFolder";
import TheTaskHighlight from "../components/TheTaskHighlight";
import { getWordCount } from "../helpers/getWordCount";
import saveToFolder from "../helpers/saveToFolder";
import { useDocumentTitle } from "../helpers/useDocumentTitle";
import { useStickyState } from "../helpers/useStickyState";

const keyName = "wordHighlight";

const helpData = {
  mainTitle: "Word Highlighter",
  title1: "What is a Word Highlighter?",
  text1: `The Word Highlighter highlights the specified words in a reading
  passage. Learners’ task can be to define the key/new words
  highlighted, find their synonyms/antonyms, make original
  sentences with them, use them in their written/oral responses,
  etc.`,
  title2: "What is the purpose of a Word Highlighter?",
  text2: `Word Highlighter is useful for developing learners’ overall
  proficiency in English. Besides enhancing their comprehension of
  a topic, they will improve their vocabulary, written and spoken
  fluency if asked to use highlighted words in their
  written/spoken responses.`,
  title3: "How do I use the Word Highlighter app?",
  text3: (
    <ol>
      <li>
        Paste a paragraph/sentence of an appropriate length and complexity level
        for your students in the text box [Enter your text here]. You can expand
        the text box by dragging with the mouse on the bottom, right corner.
      </li>
      <li>
        Specify the words to be highlighted by typing them (comma-separated) in
        the text box.
      </li>
      <li>
        Click on <strong>Create task</strong> button.
      </li>
      <li>
        Enter task directions and click
        <strong> Save to folder</strong>, go to your
        <strong>
          {" "}
          <Link to="/folder">Folder</Link>{" "}
        </strong>{" "}
        to print out your worksheet.
      </li>
      <li>
        Note that inputs from your previous session are retained if you work on
        the same machine and browser
      </li>
    </ol>
  ),
  keyName: keyName,
};

const myParams = {
  directions: [
    `A. Read the passage and find phrases that include the highlighted words 
  (e.g. word emotion – phrase a complex emotion). B. Make original sentences
   with the phrases.`,
  ],
};

const Highlight = ({ signOut }) => {
  const [workSheet, setWorkSheet] = useStickyState([]);
  const [formStore, setFormStore] = useStickyState({}, "eng-apps-" + keyName);
  const [highlightClass, setHighlightClass] = useState("Highlight");
  const [data, setData] = useState({
    keyName: keyName,
    defs: null,
    dir_text: myParams.directions[0],
    highlightClass: highlightClass,
  });
  const [isSending, setIsSending] = useState(false);
  const [error, setError] = useState();
  const [radio, setRadio] = useState("Highlight");
  const [textValue, setTextValue] = useState(formStore.textArea);
  const [customWordsText, setCustomWordsText] = useState(formStore.customWords);
  const [directionText, setDirectionText] = useState(formStore.myOwnDir);
  const [saveFolder, setSaveFolder] = useState(false);
  const [showFolder, setShowFolder] = useState(false);
  const [ownCheck, setOwnCheck] = useState(false);
  const [dirSelect, setDirSelect] = useState(myParams.directions[0]);

  const handleInputChange = (event) => {
    const target = event.target;
    const id = target.id;

    if (id === "textarea") {
      setTextValue(target.value);
      setFormStore({ ...formStore, textArea: target.value });
    } else if (id === "directionText") {
      setData({ ...data, dir_text: target.value });
      setFormStore({
        ...formStore,
        dir_text: target.value,
        myOwnDir: target.value,
      });
      setDirectionText(target.value);
    } else if (id === "customWords") {
      setFormStore({ ...formStore, customWords: target.value });
      setCustomWordsText(target.value);
    } else if (id === "ownCheck") {
      // if currently checked then user wants to un-check, hence we need to set
      // to predefined values
      let newDirText = ownCheck ? myParams.directions[0] : directionText;
      setOwnCheck(!ownCheck);
      setFormStore({
        ...formStore,
        dir_text: newDirText,
      });
      setData({ ...data, dir_text: newDirText });
    } else if (id === "dirSelect") {
      setDirSelect(target.value);
      setData({ ...data, dir_text: target.value });
    }
  };

  useDocumentTitle(helpData.mainTitle, helpData.text1);

  const myInit = useMemo(
    () => ({
      headers: { "Content-Type": "application/json" },
      body: {
        textarea: textValue,
        nthword: null,
        custom_words: customWordsText,
      },
    }),
    [textValue, customWordsText]
  );

  const memoizedCallback = useCallback(
    (event) => {
      // need to disable submit buttons post action
      event.preventDefault();
      // need to prevent more than one request sending
      if (isSending) return;
      setIsSending(true);
      API.post("apienglishreactamplify", "/items/highlight", myInit)
        .then((response) => {
          setData({ ...data, defs: response });
        })
        .then(() => setIsSending(false))
        .catch(setError);
    },
    [isSending, myInit, data]
  );

  const wordCount = getWordCount(textValue);

  let highClass = "Highlight";
  let underClass = "Underline";

  const MyHighlight = (
    <PageContainer title={helpData.mainTitle} signOut={signOut}>
      <HelpTip dictIn={helpData} />
      <Form onSubmit={memoizedCallback}>
        <Form.Group>
          <Form.Label htmlFor="textarea">
            1. Paste or type a paragraph/sentence below.
          </Form.Label>
          {textValue && textValue.length > 0 && (
            <ClearContent
              setBox={setTextValue}
              formStore={formStore}
              setForm={setFormStore}
              formKey="textArea"
            />
          )}
          <Form.Control
            as="textarea"
            id="textarea"
            rows="3"
            value={textValue}
            onChange={handleInputChange}
            required
            placeholder="Enter your text here."
          />
          <Form.Text className="text-muted">Word count: {wordCount}</Form.Text>
        </Form.Group>
        <Form.Group>
          <Form.Label htmlFor="customWords">
            2. Specify the words to be highlighted by typing them
            (comma-separated) in the text box.
          </Form.Label>
          {customWordsText && customWordsText.length > 0 && (
            <ClearContent
              setBox={setCustomWordsText}
              formStore={formStore}
              setForm={setFormStore}
              formKey="customWords"
            />
          )}
          <Form.Control
            as="textarea"
            id="customWords"
            rows="1"
            value={customWordsText}
            onChange={handleInputChange}
            required
            placeholder="Enter comma separated words."
          />
        </Form.Group>
        <Form.Group>
          <Row>
            <Col className="col-3">
              <Form.Check
                type="radio"
                id="yellow"
                checked={radio === highClass}
                value={radio}
                label={highClass}
                onChange={() => {
                  setRadio(highClass);
                  setData({ ...data, highlightClass: highClass });
                  setHighlightClass(highClass);
                }}
              />
            </Col>
            <Col className="col-3">
              <Form.Check
                type="radio"
                id="underline"
                checked={radio === underClass}
                value={radio}
                label={underClass}
                onChange={() => {
                  setRadio(underClass);
                  setData({ ...data, highlightClass: underClass });
                  setHighlightClass(underClass);
                }}
              />
            </Col>
          </Row>
        </Form.Group>
        <br />
        <Button type="submit" disabled={isSending} className="mb-3">
          Create task
        </Button>{" "}
        {(isSending || error) && (
          <LoadingOrError loading_={isSending} error_={error} />
        )}
      </Form>
      {data.defs && (
        <Form
          onSubmit={(e) =>
            saveToFolder(
              e,
              workSheet,
              data,
              setWorkSheet,
              setShowFolder,
              setSaveFolder
            )
          }
        >
          <Directions
            dirSelect={dirSelect}
            directionText={directionText}
            formStore={formStore}
            handleInputChange={handleInputChange}
            myParamsDirections={myParams.directions}
            ownCheck={ownCheck}
            setDirectionText={setDirectionText}
            setFormStore={setFormStore}
          />
          <TheTaskHighlight data={data} />
          <SaveToFolder saveFolder={saveFolder} showFolder={showFolder} />
        </Form>
      )}
    </PageContainer>
  );

  return MyHighlight;
};

export default Highlight;
