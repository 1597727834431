import {
  faFacebook,
  faLinkedin,
  faTwitter,
} from "@fortawesome/free-brands-svg-icons";
import { faEnvelope } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { memo } from "react";
import { Button, Col, Container, Row } from "react-bootstrap";
import {
  EmailShareButton,
  FacebookShareButton,
  LinkedinShareButton,
  TwitterShareButton,
} from "react-share";

const size = "2x";
const url = "http://englishapps.ca";
const body =
  "Checkout the English Apps that has all the necessary features for creating engaging worksheets for English learners.";
const subject = "English Apps";

const PageContainer = ({ title, children, signOut }) => (
  <Container>
    <Row>
      <Col md={9}>
        <h1>{title}</h1>
      </Col>
      <Col md={2}>
        <EmailShareButton url={url} subject={subject} body={body}>
          <FontAwesomeIcon
            icon={faEnvelope}
            size={size}
            pull="left"
            className="my-landing-media"
          />
        </EmailShareButton>
        <FacebookShareButton url={url} quote={body} hashtag="englishapps">
          <FontAwesomeIcon
            icon={faFacebook}
            size={size}
            pull="left"
            className="my-landing-media"
          />
        </FacebookShareButton>
        <TwitterShareButton url={url} title={body}>
          <FontAwesomeIcon
            icon={faTwitter}
            size={size}
            pull="left"
            className="my-landing-media"
          />
        </TwitterShareButton>
        <LinkedinShareButton url={url} title={subject} summary={body}>
          <FontAwesomeIcon
            icon={faLinkedin}
            size={size}
            pull="left"
            className="my-landing-media"
          />
        </LinkedinShareButton>
      </Col>
      {signOut && (
        <Col>
          <Button onClick={signOut} variant="outline-secondary" size="sm">
            Sign Out
          </Button>
        </Col>
      )}
    </Row>
    {children}
  </Container>
);

export default memo(PageContainer);
