// AWS
import { Amplify } from "aws-amplify";
// Importing the Bootstrap CSS
import "bootstrap/dist/css/bootstrap.min.css";
import { createRoot } from 'react-dom/client';
import React from "react";
import { BrowserRouter } from "react-router-dom";
import awsExports from "./aws-exports";
import MyRoutes from "./router";

Amplify.configure(awsExports);

const container = document.getElementById('root');
const root = createRoot(container);

root.render(
  <React.StrictMode>
    <BrowserRouter>
      <MyRoutes />
    </BrowserRouter>
  </React.StrictMode>
);
