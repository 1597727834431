import { faTimesCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, Col, Row } from "react-bootstrap";

const CloseButton = ({
  order,
  index,
  dirText,
  // close button state are needed for hiding in student's print
  closeTaskState,
  handleTaskClose,
  closeDirState,
  handleDirClose,
}) => {
  let classOfTaskButton = dirText ? "col-1" : "col-1 offset-11";
  return (
    <Row>
      {dirText && (
        <Col className="col-11">
          {`Task ${index + 1}`}. <b>Directions:</b> {dirText}
          {closeDirState && (
            <Button variant="link" onClick={() => handleDirClose(order)}>
              <FontAwesomeIcon icon={faTimesCircle} className="close-button" />
            </Button>
          )}
        </Col>
      )}
      {closeTaskState && (
        <Col className={classOfTaskButton}>
          <Button variant="link" onClick={() => handleTaskClose(order)}>
            <FontAwesomeIcon
              icon={faTimesCircle}
              size="2x"
              pull="right"
              className="close-button"
            />
          </Button>
        </Col>
      )}
    </Row>
  );
};

export default CloseButton;
