import { Container } from "react-bootstrap";

export const TheTaskFrequentWords = ({ data }) => {
  return (
    <>
      <Container className="askcontainer">
        <p
          dangerouslySetInnerHTML={{
            __html: data.defs["exercise"],
          }}
        />
      </Container>
    </>
  );
};

export default TheTaskFrequentWords;
