import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const LoadingOrError = ({ loading_, error_, style = { fontSize: "2rem" } }) => {
  if (loading_)
    return (
      <FontAwesomeIcon
        icon={faSpinner}
        spin
        style={style}
        className="my-spinner"
      />
    );

  if (error_) return <pre>{JSON.stringify(error_, null, 2)}</pre>;

  return null;
};

export default LoadingOrError;
