import { memo } from "react";
import Form from "react-bootstrap/Form";

const RadioElem = ({
  myParams,
  index,
  radio,
  setRadio,
  setNmLocal,
  data,
  setData,
}) => {
  let nm = myParams.nms[index];
  let description = myParams.descriptions[index];
  let firstString = nm === "verb-stem" ? "Reverse" : "Remove";
  let newDescription = `${firstString} ${description}`;

  return (
    <>
      <Form.Check
        type="radio"
        id={nm}
        checked={radio === newDescription}
        value={newDescription}
        label={newDescription}
        onChange={(e) => {
          setRadio(e.target.value);
          setNmLocal(e.target.id);
          setData({
            ...data,
            defs: null,
            dir_text: myParams.directions[index][0],
          });
        }}
      />
      <br />
    </>
  );
};

export default memo(RadioElem);
