import { API } from "aws-amplify";
import { useEffect, useRef, useState } from "react";
import { Button, ButtonGroup, Col, Form, Row, Table } from "react-bootstrap";
import Highlighter from "react-highlight-words";
import LoadingOrError from "../components/LoadingOrError";
import PageContainer from "../components/PageContainer";
import { useDocumentTitle } from "../helpers/useDocumentTitle";

const Dictionaries = () => {
  useDocumentTitle("Dictionaries", "English Language Dictionaries");

  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [nRows, setNRows] = useState(1);
  const [startRow, setStartRow] = useState(0);
  const [pageSize, setPageSize] = useState(5);
  const [goToValue, setGoToValue] = useState(1);
  const [wordSearch, setWordSearch] = useState("");
  const [dictSelect, setDictSelect] = useState("Dictionary 1");
  const [dictApiSelect, setDictApiSelect] = useState("Dictionary 1");
  const inputRef = useRef();

  let disablePreviousPage = startRow === 0 ? true : false;
  let disableNextPage =
    startRow === nRows - 1 || startRow + pageSize >= nRows ? true : false;

  let showingTo;
  if (nRows === 0) {
    showingTo = 0;
  } else if (startRow + pageSize > nRows) {
    showingTo = nRows;
  } else {
    showingTo = startRow + pageSize;
  }

  let pageText;
  if (goToValue < 1 || goToValue > Math.ceil(nRows / pageSize)) {
    pageText = "";
  } else {
    pageText = goToValue;
  }

  const handlePageSize = (e) => {
    let newPageSize = Number(e.target.value);
    setPageSize(newPageSize);

    // need to adjust go to page value
    let interim =
      Math.ceil((startRow + newPageSize) / newPageSize) >
      Math.ceil(nRows / newPageSize);
    let final = Math.ceil((startRow + newPageSize) / newPageSize);
    if (interim) {
      setGoToValue(Math.ceil(nRows / newPageSize));
    } else {
      setGoToValue(final);
    }
  };

  const nextPage = () => {
    setStartRow(
      startRow + pageSize > nRows - 1 ? nRows - 1 : startRow + pageSize
    );
    setGoToValue(goToValue + 1);
  };

  const previousPage = () => {
    let startLimit = startRow - pageSize;
    setStartRow(startLimit < 0 ? 0 : startLimit);
    setGoToValue(goToValue - 1);
  };

  const gotoRow = (pageNumber) => {
    setStartRow(pageNumber);
    if (pageNumber === 0) {
      setGoToValue(1);
    } else if (pageNumber === nRows - 1) {
      setGoToValue(Math.ceil(nRows / pageSize));
    }
  };

  const handleGotoPage = (e) => {
    setGoToValue(e.target.value);
    let rowTo = (Number(e.target.value) - 1) * pageSize;
    if (rowTo < 0) {
      return null;
    }
    rowTo = rowTo > nRows - 1 ? startRow : rowTo;
    gotoRow(rowTo);
  };

  const handleDictSelect = (e) => {
    switch (e.target.value) {
      case "Dictionary 3":
        setDictApiSelect("Apache Open Office Thesaurus");
        break;
      case "Dictionary 4":
        setDictApiSelect("Moby Thesaurus");
        break;
      default:
        setDictApiSelect(e.target.value);
        break;
    }
    setDictSelect(e.target.value);

    setLoading(true);
    gotoRow(0);
  };

  const myInit = {
    headers: { "Content-Type": "application/json" },
    body: {
      dictionary: dictApiSelect,
      startRow: startRow,
      pageSize: pageSize,
      dicFilter: wordSearch,
    },
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      // here the wordSearch will have the value when we set the timer
      // and we compare it with the current value
      if (wordSearch === inputRef.current.value) {
        API.post("apienglishreactamplify", "/items/dictionaries", myInit)
          .then((response) => {
            setData(response.data);
            setNRows(response.length);
          })
          .then(() => setLoading(false));
      }
    }, 500);
    // need to make sure we do not have timers left with each effect call
    return () => {
      clearTimeout(timer);
    };

    // eslint-disable-next-line
  }, [pageSize, startRow, wordSearch, dictApiSelect, inputRef]);

  const PageOrLoad = () => {
    if (loading) {
      return (
        <LoadingOrError loading_={loading} style={{ fontSize: "2.5rem" }} />
      );
    }
    return (
      <p>
        Showing {nRows > 0 ? startRow + 1 : 0} to {showingTo} of {nRows}
        &nbsp;rows&nbsp;
        <strong>|</strong>&nbsp;Page {pageText} of&nbsp;
        {Math.ceil(nRows / pageSize)}
      </p>
    );
  };

  return (
    <PageContainer title={"Dictionaries"}>
      <Form onSubmit={(e) => e.preventDefault()}>
        <Row>
          <Col className="col-3">
            <Form.Group controlId="showPageNum">
              <Form.Label>Show</Form.Label>
              <Form.Select value={pageSize} onChange={handlePageSize}>
                {[5, 15, 25, 50].map((pageSize) => (
                  <option key={pageSize} value={pageSize}>
                    {pageSize} rows
                  </option>
                ))}
              </Form.Select>
            </Form.Group>
          </Col>
          <Col className="col-6">
            <Row>
              <PageOrLoad />
            </Row>
            <Row>
              <ButtonGroup size="sm">
                <Button
                  onClick={() => gotoRow(0)}
                  disabled={disablePreviousPage}
                >
                  {"<<"}
                </Button>
                <Button
                  onClick={() => previousPage()}
                  disabled={disablePreviousPage}
                >
                  {"<"}
                </Button>
                <Button onClick={() => nextPage()} disabled={disableNextPage}>
                  {">"}
                </Button>
                <Button
                  onClick={() => gotoRow(nRows - 1)}
                  disabled={disableNextPage}
                >
                  {">>"}
                </Button>
              </ButtonGroup>
            </Row>
          </Col>
          <Col className="col-3">
            <Form.Group controlId="goToPage">
              <Form.Label>Go to page:&nbsp;</Form.Label>
              <Form.Control
                required
                className="col-md-5"
                as="input"
                type="number"
                value={goToValue}
                min={1}
                max={Math.ceil(nRows / pageSize)}
                onChange={handleGotoPage}
              />
            </Form.Group>
          </Col>
        </Row>
      </Form>
      <br />
      <Table striped bordered hover>
        <thead>
          <tr>
            <td colSpan="3">
              <Form onSubmit={(e) => e.preventDefault()}>
                <Row>
                  <Col>
                    <Form.Control
                      ref={inputRef}
                      type="text"
                      autoFocus
                      value={wordSearch}
                      onChange={(e) => {
                        setWordSearch(e.target.value);
                        setStartRow(0);
                        setGoToValue(1);
                      }}
                      placeholder={`type for a word search...`}
                    />
                  </Col>
                  <Col>
                    <Form.Select value={dictSelect} onChange={handleDictSelect}>
                      <option>Dictionary 1</option>
                      <option>Dictionary 2</option>
                      <option>Dictionary 3</option>
                      <option>Dictionary 4</option>
                    </Form.Select>
                  </Col>
                </Row>
              </Form>
            </td>
          </tr>
          <tr>
            <th>Word</th>
            <th>Part of Speech</th>
            <th>Definition</th>
          </tr>
        </thead>
        <tbody>
          {data.map((row, i) => (
            <tr key={i}>
              {Object.keys(row).map((key, ii) => (
                <td key={ii}>
                  <Highlighter
                    highlightClassName="HighlightClass"
                    searchWords={[wordSearch]}
                    autoEscape={true}
                    textToHighlight={row[key]}
                  />
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </Table>
    </PageContainer>
  );
};

export default Dictionaries;
