import { memo } from "react";
import Form from "react-bootstrap/Form";

const NumberInputBlock = ({ nthValue, handleInputChange, namePart }) => {
  let theString = namePart === "verb-stem" ? "Reverse" : "Remove";

  return (
    <Form.Group>
      <Form.Label htmlFor="n-word">
        {theString} every Nth {namePart} in a paragraph.
      </Form.Label>
      <Form.Control
        as="input"
        type="number"
        id="n-word"
        min="1"
        value={nthValue}
        onChange={handleInputChange}
        required
      />
    </Form.Group>
  );
};

export default memo(NumberInputBlock);
