import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Outlet } from "react-router-dom";
import "./App.css";
import NavBarMy from "./components/NavBarMy";
import SiteMap from "./components/SiteMap";

const App = () => {
  return (
    <>
      <NavBarMy />
      <Outlet />
      <SiteMap />
    </>
  );
};

export default App;
