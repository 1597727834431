import { API } from "aws-amplify";
import { useCallback, useEffect, useRef, useState } from "react";
import { Button, Form } from "react-bootstrap";
import { Link, Outlet, useParams } from "react-router-dom";
import Directions from "../components/Directions";
import HelpTip from "../components/HelpTip";
import LoadingOrError from "../components/LoadingOrError";
import PageContainer from "../components/PageContainer";
import SaveToFolder from "../components/SaveToFolder";
import TheTaskSynonyms from "../components/TheTaskSynonyms";
import saveToFolder from "../helpers/saveToFolder";
import { useDocumentTitle } from "../helpers/useDocumentTitle";
import { useStickyState } from "../helpers/useStickyState";

const keyName = "synonyms";

const helpData = {
  mainTitle: "Synonyms & Antonyms Matching",
  title1: "What is Synonyms and Antonyms Matching?",
  text1: `The Synonyms and Antonyms Matching generates an unlimited number of 
  synonym and antonym pairs. Learners’ task is to match the pairs relying on
  their overall proficiency in the English language.`,
  title2: "What is the purpose of a Synonyms and Antonyms Matching?",
  text2: `Synonyms and Antonyms Matching is used for developing learners’
  vocabulary in English as a second/foreign language. Learners will also improve
  their pronunciation in English if asked to practice reading the pairs aloud.`,
  title3: "How do I use the Synonyms and Antonyms Matching app?",
  text3: (
    <ol>
      <li>
        Click on <strong>Create task</strong> button.
      </li>
      <li>
        Enter task directions and click
        <strong> Save to folder</strong>, go to your
        <strong>
          {" "}
          <Link to="/folder">Folder</Link>{" "}
        </strong>{" "}
        to print out your worksheet.
      </li>
      <li>
        Note that inputs from your previous session are retained if you work on
        the same machine and browser
      </li>
    </ol>
  ),
  keyName: keyName,
};

const myParams = {
  directions: {
    "synonyms-basic": [`Match the synonyms (pair work).`],
    "synonyms-advanced": [`Match the synonyms (pair work).`],
    antonyms: [`Match the antonyms (pair work).`],
  },
};

export default function SynonymsOutlet() {
  return <Outlet />;
}

export const Synonyms = ({ signOut }) => {
  let params = useParams();
  let synonymID = params.synonymID;
  // if user navigates to synant then default to basic
  if (!synonymID) {
    synonymID = "synonyms-basic";
  }

  let synOrAnt = useRef(synonymID);
  const [workSheet, setWorkSheet] = useStickyState([]);
  const [formStore, setFormStore] = useStickyState({}, "eng-apps-" + keyName);
  const [data, setData] = useState({
    keyName: synonymID,
    defs: null,
    dir_text: myParams.directions[synonymID][0],
  });
  const [isSending, setIsSending] = useState(false);
  const [error, setError] = useState();
  const [saveFolder, setSaveFolder] = useState(false);
  const [showFolder, setShowFolder] = useState(false);
  const [directionText, setDirectionText] = useState(formStore.myOwnDir);
  const [ownCheck, setOwnCheck] = useState(false);
  const [dirSelect, setDirSelect] = useState(myParams.directions[synonymID][0]);

  // need to reset data if one switched from ano to syn of vice versa
  // otherwise wrong test/keys are displayed
  useEffect(() => {
    if (synonymID !== synOrAnt.current) {
      synOrAnt.current = synonymID;
      setData({
        ...data,
        defs: null,
        dir_text: myParams.directions[synonymID][0],
        keyName: synonymID,
      });
    }
  }, [synonymID, data]);

  const handleInputChange = (event) => {
    const target = event.target;
    const id = target.id;

    if (id === "directionText") {
      setData({ ...data, dir_text: target.value });
      setFormStore({
        ...formStore,
        dir_text: target.value,
        myOwnDir: target.value,
      });
      setDirectionText(target.value);
    } else if (id === "ownCheck") {
      // if currently checked then user wants to un-check, hence we need to set
      // to predefined values
      let newDirText = ownCheck
        ? myParams.directions[synonymID][0]
        : directionText;
      setOwnCheck(!ownCheck);
      setFormStore({
        ...formStore,
        dir_text: newDirText,
      });
      setData({ ...data, dir_text: newDirText });
    } else if (id === "dirSelect") {
      setDirSelect(target.value);
      setData({ ...data, dir_text: target.value });
    }
  };

  let pageTitle = useDocumentTitle(synonymID, helpData.text1);
  let titleText = `${helpData.mainTitle}, ${pageTitle}`;

  const memoizedCallback = useCallback(
    (event) => {
      // need to disable submit buttons post action
      event.preventDefault();
      // need to prevent more than one request sending
      if (isSending) return;
      setIsSending(true);
      API.get("apienglishreactamplify", `/items/${synonymID}`, null)
        .then((response) => {
          setData({ ...data, defs: response });
        })
        .then(() => setIsSending(false))
        .catch(setError);
    },
    [isSending, synonymID, data]
  );

  return (
    <PageContainer title={titleText} signOut={signOut}>
      <HelpTip dictIn={helpData} />
      <Form onSubmit={memoizedCallback}>
        <Button type="submit" disabled={isSending} className="mb-3">
          Create task
        </Button>{" "}
        {(isSending || error) && (
          <LoadingOrError loading_={isSending} error_={error} />
        )}
      </Form>
      {data.defs && (
        <Form
          onSubmit={(e) =>
            saveToFolder(
              e,
              workSheet,
              data,
              setWorkSheet,
              setShowFolder,
              setSaveFolder
            )
          }
        >
          <Directions
            dirOrder="1"
            dirSelect={dirSelect}
            directionText={directionText}
            formStore={formStore}
            handleInputChange={handleInputChange}
            myParamsDirections={myParams.directions[synonymID]}
            ownCheck={ownCheck}
            setDirectionText={setDirectionText}
            setFormStore={setFormStore}
          />
          <TheTaskSynonyms data={data} />
          <SaveToFolder saveFolder={saveFolder} showFolder={showFolder} />
        </Form>
      )}
    </PageContainer>
  );
};
