import { API } from "aws-amplify";
import { useCallback, useMemo, useState } from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import ClearContent from "../components/ClearContent";
import Directions from "../components/Directions";
import HelpTip from "../components/HelpTip";
import LoadingOrError from "../components/LoadingOrError";
import NumberInputBlock from "../components/NumberInputBlock";
import PageContainer from "../components/PageContainer";
import RadioElem from "../components/RadioElem";
import SaveToFolder from "../components/SaveToFolder";
import TheTaskClozeTest from "../components/TheTaskClozeTest";
import { getWordCount } from "../helpers/getWordCount";
import { range } from "../helpers/range";
import saveToFolder from "../helpers/saveToFolder";
import { useDocumentTitle } from "../helpers/useDocumentTitle";
import { useStickyState } from "../helpers/useStickyState";

const keyName = "clozeTest";

const helpData = {
  mainTitle: "Cloze Test",
  title1: "What is a Cloze Test?",
  text1: `Cloze test is a technique of deleting words from a passage and
  replacing them with blanks. The passage is provided to students, whose task is
  to insert words as they read to construct meaning from the text.`,
  title2: "What is the purpose of Cloze Tests?",
  text2: `As a rule, Cloze tests are used for developing and assessing English
  learners’ monitoring of their reading comprehension, critical thinking and
  vocabulary development in the English language.`,
  title3: "How do I use the Cloze Test app?",
  text3: (
    <ol>
      <li>
        After you have selected a paragraph of an appropriate length and
        complexity level for your students, paste it in the text box [Enter your
        text here]. You can expand the text box by dragging with the mouse on
        the bottom, right corner.
      </li>
      <li>
        Choose the function for removing words from the passage from the the
        options.
        <ul>
          <li>
            For <u>every N-th word</u> function, specify the rate at which to
            remove words, i.e., for removing every third word, choose 3, for
            removing every fifth word, choose 5, etc, and click on
            <strong> Create task </strong>
            button.
          </li>
          <li>
            To <u>remove exact words</u> type the words to be removed separated
            by commas in the text box. For all other functions, just choose the
            function and click on <strong>Create task</strong> button.
          </li>
        </ul>
      </li>
      <li>
        Click on <strong>Create task</strong> button.
      </li>
      <li>
        Enter task directions and click
        <strong> Save to folder</strong>, go to your
        <strong>
          {" "}
          <Link to="/folder">Folder</Link>{" "}
        </strong>{" "}
        to print out your worksheet.
      </li>
      <li>
        Note that inputs from your previous session are retained if you work on
        the same machine and browser
      </li>
    </ol>
  ),
  keyName: keyName,
};

const myParams = {
  nms: [
    "word",
    "exact-word",
    "preposition",
    "article",
    "conjunction",
    "adjective",
    "adverb",
    "verb-stem",
    "verb",
    "punctuation",
  ],
  descriptions: [
    "every N-th word",
    "exact words",
    "prepositions",
    "articles (a/an - the)",
    "conjunctions",
    "adjectives",
    "adverbs",
    "verbs to base",
    "verbs",
    "punctuation",
  ],
  directions: [
    [
      `Listen to the audio narration to supply the missing words. Use the answer
     key to check your answers.`,
      `Use the words provided below to complete the sentences. Refer to 
    the original article/passage (OR use the answer key) to check your 
    answers.`,
    ],
    [
      `Read the passage and fill in the gaps with the words provided in the 
      table below.`,
    ],
    [
      `Use the prepositions provided below to complete the sentences. 
    Refer to the original passage (OR use the answer key) to check your 
    answers.`,
    ],
    [
      `A. Fill in the definite/indefinite articles in the sentences below. 
    Use the answer key to check your answers. B. Explain the usage of articles
    in the sentences.`,
    ],
    [
      `Use the conjunctions provided below to complete the sentences. 
    Refer to the original article/passage (OR use the answer key) to check your 
    answers.`,
      `Listen to the audio narration to supply the missing conjunctions. Use 
      the answer key to check your answers.`,
    ],
    [
      `Use the adjectives provided below to complete the sentences. 
    Refer to the original article/passage (OR use the answer key) to check your
    answers.`,
      `Listen to the audio narration to supply the missing adjectives. Use the
    audio script or the answer key to check your answers.`,
    ],
    [
      `Use the adverbs provided below to complete the sentences. 
    Refer to the original article/passage (OR use the answer key) to check your
    answers.`,
      `Listen to the audio narration to supply the missing adverbs. Use the
    audio script or the answer key to check your answers.`,
    ],
    [
      `Read the passage and fill in the gaps with the correct form of 
    nouns/verbs provided in brackets. Use the answer key to check your answers.`,
      `Listen to the audio narration and fill in the gaps with the correct form 
    of nouns/verbs provided in brackets. Use the audio script to check your 
    answers.`,
    ],
    [
      `Listen to the audio narration to supply the missing verbs. Use the audio 
    script or the answer key to check your answers.`,
    ],
    [
      `Punctuate the passage then refer to the original text to check your 
    answers. Explain the usage of (e.g. commas in the 1st and 3rd sentences).`,
    ],
  ],
};

const namePart = (radio) => {
  let theString = radio === "Reverse verbs to base" ? "Reverse" : "Remove";
  let subString = radio.substring(theString.length).trim();
  let ind = myParams.descriptions.indexOf(subString);
  let final = myParams.nms[ind];
  return [ind, final];
};

const Highlight = () => {
  const [workSheet, setWorkSheet] = useStickyState([]);
  const [formStore, setFormStore] = useStickyState({}, "eng-apps-" + keyName);
  const [radio, setRadio] = useState("Remove every N-th word");
  let radioIndex = namePart(radio)[0];
  const [data, setData] = useState({
    keyName: keyName,
    defs: null,
    dir_text: myParams.directions[radioIndex][0],
  });
  const [isSending, setIsSending] = useState(false);
  const [error, setError] = useState();
  const [textValue, setTextValue] = useState(formStore.textArea);
  const [customWordsText, setCustomWordsText] = useState(formStore.customWords);
  const [directionText, setDirectionText] = useState(formStore.myOwnDir);
  const [saveFolder, setSaveFolder] = useState(false);
  const [showFolder, setShowFolder] = useState(false);
  const [nmLocal, setNmLocal] = useState("word");
  const [nthValue, setNthValue] = useState(3);
  const [ownCheck, setOwnCheck] = useState(false);
  const [dirSelect, setDirSelect] = useState(
    myParams.directions[radioIndex][0]
  );

  const handleInputChange = (event) => {
    const target = event.target;
    const id = target.id;

    if (id === "textarea") {
      setFormStore({ ...formStore, textArea: target.value });
      setTextValue(target.value);
    } else if (id === "directionText") {
      setData({ ...data, dir_text: target.value });
      setFormStore({
        ...formStore,
        dir_text: target.value,
        myOwnDir: target.value,
      });
      setDirectionText(target.value);
    } else if (id === "customWords") {
      setFormStore({ ...formStore, customWords: target.value });
      setCustomWordsText(target.value);
    } else if (id === "ownCheck") {
      // if currently checked then user wants to un-check, hence we need to set
      // to predefined values
      let newDirText = ownCheck
        ? myParams.directions[radioIndex][0]
        : directionText;
      setOwnCheck(!ownCheck);
      setFormStore({
        ...formStore,
        dir_text: newDirText,
      });
      setData({ ...data, dir_text: newDirText });
    } else if (id === "dirSelect") {
      setDirSelect(target.value);
      setData({ ...data, dir_text: target.value });
    }
    if (setNthValue && id === "n-word") setNthValue(target.value);
  };

  useDocumentTitle(helpData.mainTitle, helpData.text1);

  const myInit = useMemo(
    () => ({
      headers: { "Content-Type": "application/json" },
      body: {
        textarea: textValue,
        nthword: nthValue,
        custom_words: customWordsText,
      },
    }),
    [textValue, nthValue, customWordsText]
  );

  const memoizedCallback = useCallback(
    (event) => {
      // need to disable submit buttons post action
      event.preventDefault();
      // need to prevent more than one request sending
      if (isSending) return;
      setIsSending(true);
      API.post("apienglishreactamplify", `/items/cloze-test-${nmLocal}`, myInit)
        .then((response) => {
          setData({ ...data, defs: response });
        })
        .then(() => setIsSending(false))
        .catch(setError);
    },
    [isSending, myInit, nmLocal, data]
  );

  const wordCount = getWordCount(textValue);

  return (
    <PageContainer title={helpData.mainTitle}>
      <HelpTip dictIn={helpData} />
      <Form onSubmit={memoizedCallback}>
        <Form.Group>
          <Form.Label htmlFor="textarea">
            1. Paste or type a paragraph below.
          </Form.Label>
          {textValue && textValue.length > 0 && (
            <ClearContent
              setBox={setTextValue}
              formStore={formStore}
              setForm={setFormStore}
              formKey="textArea"
            />
          )}
          <Form.Control
            as="textarea"
            id="textarea"
            rows="3"
            value={textValue}
            onChange={handleInputChange}
            required
            placeholder="Enter your text here."
          />
          <Form.Text className="text-muted">Word count: {wordCount}</Form.Text>
        </Form.Group>
        <Form.Group>
          <Row>
            {range(0, myParams.nms.length - 1, 1).map((index, i) => (
              <Col key={i}>
                <RadioElem
                  myParams={myParams}
                  index={index}
                  radio={radio}
                  setRadio={setRadio}
                  setNmLocal={setNmLocal}
                  data={data}
                  setData={setData}
                />
              </Col>
            ))}
          </Row>
        </Form.Group>
        {radio !== "Remove exact words" && (
          <NumberInputBlock
            nthValue={nthValue}
            handleInputChange={handleInputChange}
            namePart={namePart(radio)[1]}
          />
        )}
        {radio === "Remove exact words" && (
          <Form.Group controlId="customWords">
            <Form.Label>
              Provide the comma separated words to be removed.
            </Form.Label>
            {customWordsText && customWordsText.length > 0 && (
              <ClearContent
                setBox={setCustomWordsText}
                formStore={formStore}
                setForm={setFormStore}
                formKey="customWords"
              />
            )}
            <Form.Control
              as="textarea"
              rows="1"
              value={customWordsText}
              onChange={handleInputChange}
              required
            />
          </Form.Group>
        )}
        <br></br>
        <Button type="submit" disabled={isSending} className="mb-3">
          Create task
        </Button>{" "}
        {(isSending || error) && (
          <LoadingOrError loading_={isSending} error_={error} />
        )}
      </Form>
      {data.defs && (
        <Form
          onSubmit={(e) =>
            saveToFolder(
              e,
              workSheet,
              data,
              setWorkSheet,
              setShowFolder,
              setSaveFolder
            )
          }
        >
          <Directions
            dirOrder="2"
            dirSelect={dirSelect}
            directionText={directionText}
            formStore={formStore}
            handleInputChange={handleInputChange}
            myParamsDirections={myParams.directions[radioIndex]}
            ownCheck={ownCheck}
            setDirectionText={setDirectionText}
            setFormStore={setFormStore}
          />

          <TheTaskClozeTest data={data} />

          <SaveToFolder saveFolder={saveFolder} showFolder={showFolder} />
        </Form>
      )}
    </PageContainer>
  );
};

export default Highlight;
