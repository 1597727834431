const getBigOrder = (workSheet) => {
  // need the highest order of all worksheet objects
  let worksheetLength = workSheet.length;
  let bigOrder;

  const orders = workSheet.map((item) => item.order);
  const maxOrder = Math.max(...orders);

  if (worksheetLength === 0) {
    bigOrder = 1;
  } else {
    bigOrder = maxOrder + 1;
  }
  return bigOrder;
};

export default getBigOrder;
