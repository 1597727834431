import { Container } from "react-bootstrap";

export const TheTaskQuestionMaker = ({ data }) => {
  return (
    <>
      <Container className="askcontainer">
        {data["exercise"].map((item, i) => (
          <p key={i}>{item}</p>
        ))}
      </Container>
    </>
  );
};

export default TheTaskQuestionMaker;
