import { Table } from "react-bootstrap";

export const TheTaskSynonyms = ({ data, ansKeyState = true }) => {
  return (
    <>
      <Table
        striped
        dangerouslySetInnerHTML={{
          __html: data.defs["exercise"],
        }}
      ></Table>
      <br />
      {ansKeyState && (
        <>
          <code>Answer Key</code>
          <p>{data.defs["answer_key"]}</p>
        </>
      )}
    </>
  );
};

export default TheTaskSynonyms;
