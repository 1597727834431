import { memo } from "react";
import { Breadcrumb, Container } from "react-bootstrap";
import { Link, useLocation } from "react-router-dom";

const items = [
  { href: "/dictionaries", name: "Word Lookup" },
  { href: "/dictionaries-multiple", name: "Vocabulary" },
  { href: "/cloze-test", name: "Cloze Test" },
  { href: "/multiple-choice", name: "Multiple Choice Test" },
  { href: "/word-shuffler", name: "Sentence Shuffler" },
  { href: "/sufpref/suffix-matcher", name: "Suffix Matcher" },
  { href: "/sufpref/prefix-matcher", name: "Prefix Matcher" },
  { href: "/frequent-words", name: "Frequent Word" },
  { href: "/matching", name: "Matching Task" },
  { href: "/synant/synonyms-basic", name: "Synonyms Basic" },
  { href: "/synant/synonyms-advanced", name: "Synonyms Advanced" },
  { href: "/synant/antonyms", name: "Antonyms" },
  { href: "/highlight", name: "Word Highlighter" },
  // { href: "/question-maker", name: "Question Maker" },
  { href: "/folder", name: "Folder" },
  { href: "/privacy-policy", name: "Privacy Policy" },
  { href: "/terms-of-use", name: "Terms of Use" },
  { href: "/contact-us", name: "Contact Us" },
];

const SiteMap = () => {
  let location = useLocation().pathname;
  // if it is the outlet then default to some child page
  let outletPage = ["/sufpref", "/synant"].indexOf(location);
  if (outletPage !== -1) {
    location = ["/sufpref/suffix-matcher", "/synant/synonyms-basic"][
      outletPage
    ];
  }

  return (
    <Container className="mt-2">
      <Breadcrumb>
        {items.map((item, i) =>
          item.href === location ? (
            <Breadcrumb.Item active key={i}>
              {item.name}
            </Breadcrumb.Item>
          ) : (
            <Breadcrumb.Item
              linkProps={{ to: item.href }}
              linkAs={Link}
              key={i}
            >
              {item.name}
            </Breadcrumb.Item>
          )
        )}
      </Breadcrumb>
      <br />
      <Container className="text-center">
        <h6 className="fixed-bottom">
          Copyright © 2020-2022 EnglishApps v0.0.117. All Rights Reserved.
        </h6>
      </Container>
    </Container>
  );
};

export default memo(SiteMap);
