import { API } from "aws-amplify";
import { useCallback, useMemo, useState } from "react";
import { Button, Form } from "react-bootstrap";
import { Link } from "react-router-dom";
import ClearContent from "../components/ClearContent";
import Directions from "../components/Directions";
import HelpTip from "../components/HelpTip";
import LoadingOrError from "../components/LoadingOrError";
import PageContainer from "../components/PageContainer";
import SaveToFolder from "../components/SaveToFolder";
import TheTaskWordShuffler from "../components/TheTaskWordShuffler";
import { getWordCount } from "../helpers/getWordCount";
import saveToFolder from "../helpers/saveToFolder";
import { useDocumentTitle } from "../helpers/useDocumentTitle";
import { useStickyState } from "../helpers/useStickyState";

const keyName = "wordShuffler";

const helpData = {
  mainTitle: "Sentence Shuffler",
  title1: "What is a Sentence Shuffler?",
  text1: `The Sentence Shuffler rearranges the order of words in a sentence. 
  Learners must put the words in grammatically correct order.`,
  title2: "What is the purpose of a Sentence Shuffler?",
  text2: `A Sentence Shuffler is indispensable for creating worksheets to practice
  word order and sentence structure. Learners will improve not only their
  understanding of the structure of English sentences but also their
  awareness of various elements of the language.`,
  title3: "How do I use the Sentence Shuffler app?",
  text3: (
    <ol>
      <li>
        Paste a paragraph with sentences of an appropriate length and complexity
        level for your students in the text box [Enter your text here]. You can
        expand the text box by dragging with the mouse on the bottom, right
        corner.
      </li>
      <li>
        Specify how many words to keep intact by choosing a number, e.g., if you
        choose 3, three words from the beginning of a sentence will be kept
        intact.
      </li>
      <li>
        Click on <strong>Create task</strong> button.
      </li>
      <li>
        Enter task directions and click
        <strong> Save to folder</strong>, go to your
        <strong>
          {" "}
          <Link to="/folder">Folder</Link>{" "}
        </strong>{" "}
        to print out your worksheet.
      </li>
      <li>
        Note that inputs from your previous session are retained if you work on
        the same machine and browser
      </li>
    </ol>
  ),
  keyName: keyName,
};

const myParams = {
  directions: [
    `The words are rearranged in the sentences below; your task is to put them
  in the correct order. Use the answer key to check your answers.`,
  ],
};

const WordShuffler = ({ signOut }) => {
  const [workSheet, setWorkSheet] = useStickyState([]);
  const [formStore, setFormStore] = useStickyState({}, "eng-apps-" + keyName);
  const [data, setData] = useState({
    keyName: keyName,
    defs: null,
    dir_text: myParams.directions[0],
  });
  const [nthValue, setNthValue] = useState(3);
  const [isSending, setIsSending] = useState(false);
  const [error, setError] = useState();
  const [textValue, setTextValue] = useState(formStore.textArea);
  const [directionText, setDirectionText] = useState(formStore.myOwnDir);
  const [saveFolder, setSaveFolder] = useState(false);
  const [showFolder, setShowFolder] = useState(false);
  const [ownCheck, setOwnCheck] = useState(false);
  const [dirSelect, setDirSelect] = useState(myParams.directions[0]);

  const handleInputChange = (event) => {
    const target = event.target;
    const id = target.id;

    if (id === "textarea") {
      setTextValue(target.value);
      setFormStore({ ...formStore, textArea: target.value });
    } else if (id === "directionText") {
      setData({ ...data, dir_text: target.value });
      setFormStore({
        ...formStore,
        dir_text: target.value,
        myOwnDir: target.value,
      });
      setDirectionText(target.value);
    } else if (id === "ownCheck") {
      // if currently checked then user wants to un-check, hence we need to set
      // to predefined values
      let newDirText = ownCheck ? myParams.directions[0] : directionText;
      setOwnCheck(!ownCheck);
      setFormStore({
        ...formStore,
        dir_text: newDirText,
      });
      setData({ ...data, dir_text: newDirText });
    } else if (id === "dirSelect") {
      setDirSelect(target.value);
      setData({ ...data, dir_text: target.value });
    } else if (id === "nthValue") setNthValue(target.value);
  };

  const myInit = useMemo(
    () => ({
      headers: { "Content-Type": "application/json" },
      body: {
        textarea: textValue,
        nthword: nthValue,
        custom_words: null,
      },
    }),
    [textValue, nthValue]
  );

  const memoizedCallback = useCallback(
    (event) => {
      // need to disable submit buttons post action
      event.preventDefault();
      // need to prevent more than one request sending
      if (isSending) return;
      setIsSending(true);
      API.post("apienglishreactamplify", "/items/paragraph-shuffler", myInit)
        .then((response) => {
          setData({ ...data, defs: response });
        })
        .then(() => setIsSending(false))
        .catch(setError);
    },
    [isSending, myInit, data]
  );

  useDocumentTitle(helpData.mainTitle, helpData.text1);

  const wordCount = getWordCount(textValue);

  return (
    <PageContainer title={helpData.mainTitle} signOut={signOut}>
      <HelpTip dictIn={helpData} />
      <Form onSubmit={memoizedCallback}>
        <Form.Group>
          <Form.Label htmlFor="textarea">
            1. Paste or type a sentence(s) below.
          </Form.Label>
          {textValue && textValue.length > 0 && (
            <ClearContent
              setBox={setTextValue}
              formStore={formStore}
              setForm={setFormStore}
              formKey="textArea"
            />
          )}
          <Form.Control
            as="textarea"
            id="textarea"
            rows="3"
            value={textValue}
            onChange={handleInputChange}
            required
            placeholder="Enter your text here."
          />
          <Form.Text className="text-muted">Word count: {wordCount}</Form.Text>
        </Form.Group>
        <Form.Group>
          <Form.Label htmlFor="nthValue">
            2. Choose a number to specify how many words to keep intact.
          </Form.Label>
          <Form.Control
            as="input"
            type="number"
            id="nthValue"
            name="nthValue"
            min="1"
            value={nthValue}
            onChange={handleInputChange}
            required
          />
        </Form.Group>
        <br />
        <Button type="submit" disabled={isSending} className="mb-3">
          Create task
        </Button>{" "}
        {(isSending || error) && (
          <LoadingOrError loading_={isSending} error_={error} />
        )}
      </Form>
      {data.defs && (
        <Form
          onSubmit={(e) =>
            saveToFolder(
              e,
              workSheet,
              data,
              setWorkSheet,
              setShowFolder,
              setSaveFolder
            )
          }
        >
          <Directions
            dirSelect={dirSelect}
            directionText={directionText}
            formStore={formStore}
            handleInputChange={handleInputChange}
            myParamsDirections={myParams.directions}
            ownCheck={ownCheck}
            setDirectionText={setDirectionText}
            setFormStore={setFormStore}
          />
          <TheTaskWordShuffler data={data} />
          <SaveToFolder saveFolder={saveFolder} showFolder={showFolder} />
        </Form>
      )}
    </PageContainer>
  );
};

export default WordShuffler;
