import { Form } from "react-bootstrap";
import ClearContent from "../components/ClearContent";

const Directions = ({
  dirOrder = "3",
  handleInputChange,
  ownCheck,
  directionText,
  setDirectionText,
  dirSelect,
  formStore,
  setFormStore,
  myParamsDirections,
}) => {
  return (
    <>
      <Form.Label htmlFor="dirSelect">
        {dirOrder}. Choose task directions from the list or type your own by
        checking the box below.
      </Form.Label>
      <Form.Group>
        <Form.Check
          label="Type my own direction."
          id="ownCheck"
          onChange={handleInputChange}
          value={ownCheck}
          inline
        />
        {ownCheck && directionText && directionText.length > 0 && (
          <ClearContent
            setBox={setDirectionText}
            formStore={formStore}
            setForm={setFormStore}
            formKey="directionText"
          />
        )}
        {!ownCheck && (
          <Form.Select
            id="dirSelect"
            onChange={handleInputChange}
            value={dirSelect}
            className="my-3"
          >
            {/* myParams.directions[radioIndex] */}
            {myParamsDirections.map((dir_, i) => (
              <option key={i}>{dir_}</option>
            ))}
          </Form.Select>
        )}
      </Form.Group>
      {ownCheck && (
        <Form.Control
          as="textarea"
          id="directionText"
          rows="1"
          value={directionText}
          onChange={handleInputChange}
          placeholder="Enter task directions here."
          required
          className="my-3"
        />
      )}
    </>
  );
};

export default Directions;
