import { Link, useLocation } from "react-router-dom";
import Container from "react-bootstrap/Container";
import { memo } from "react";

const NoMatch = () => {
  let location = useLocation();

  return (
    <Container>
      <h2>
        😱 No match for URL <code>{location.pathname}</code>
      </h2>
      <h3>
        Please visit our home page <Link to="/">Home</Link>
      </h3>
    </Container>
  );
};

export default memo(NoMatch);
