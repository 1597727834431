export const TheTaskMultipleChoice = ({ data, ansKeyState = true }) => {
  return (
    <>
      <p>{data["text_area"]}</p>
      <p>{data.defs["def_word"]}</p>
      <ol type="a">
        <li>{data.defs["a"]}</li>
        <li>{data.defs["b"]}</li>
        <li>{data.defs["c"]}</li>
        <li>{data.defs["d"]}</li>
      </ol>
      {ansKeyState && (
        <>
          <code>Answer Key</code>
          <p>{data.defs["answer_key"]}</p>
        </>
      )}
    </>
  );
};

export default TheTaskMultipleChoice;
