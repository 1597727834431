import { Container } from "react-bootstrap";

const PrivacyPolicy = () => (
  <Container>
    <h1>Privacy Policy</h1>
    <p>
      Please read our privacy policy carefully to get a clear understanding of
      how we collect, use, protect, or otherwise handle your Personally
      Identifiable Information in accordance with our website.
    </p>
    <p>
      By using any EnglishApps® Services, you are agreeing to the terms in this
      Privacy Policy. Be sure to occasionally check back for updates.
    </p>
    <p>
      This Privacy Policy is intended to help you better understand how we
      collect, use, and store your Personal Information—whether you are a
      Subscriber to an EnglishApps product such as (products listed here)
      (together, the “Service”), or an end-user that uses our technology, or
      whether you’re simply visiting this Website.
    </p>
    <p>
      By using the Service, or visiting this Website, directly or through an
      individual or organization using the Service, you are agreeing to the
      terms of this Privacy Policy and, as applicable, the product’s Terms of
      Use or Master Subscription Agreement.
    </p>
    <p>
      We may update this Privacy Policy from time to time in order to reflect
      changes to our privacy practices or for other operational, legal, or
      regulatory reasons. If we make material changes to this Privacy Policy, we
      will give you notice of such changes by posting the revised policy on this
      Website, and where appropriate, by other means. By continuing to use this
      Website or the Service after these changes are posted, you agree to the
      revised policy.
    </p>
    <p>
      Note to Residents of the European Union: In order to comply with the
      requirements of applicable privacy laws, this Privacy Policy outlines the
      legal basis on which EnglishApps processes your personal data (also
      referred to as Personal Information herein). Please see the relevant
      sections below for additional information as required by applicable laws.
    </p>
    <p>
      We believe your business is no one else’s. Your privacy is important to
      you and to us. So we’ll protect the information you share with us. To
      protect your privacy, EnglishApps follows key principles in accordance
      with worldwide practices for customer privacy and data protection.
    </p>
    <p>
      We won’t sell or give away your name, mail address, phone number, email
      address, or any other information to anyone. We’ll use security measures
      to protect your information from unauthorized users.
    </p>
    <h2>NOTICE</h2>
    <p>
      We will ask you when we need information that personally identifies you
      (Personal Information) or allows us to contact you. We use your Personal
      Information for four primary purposes:
    </p>
    <ul>
      <li>
        To make the Website easier for you to use by not having to enter
        information more than once.
      </li>
      <li>To help you quickly find software, services, or information.</li>
      <li>
        To help us provide the Service and create content most relevant to you.
      </li>
      <li>
        To communicate with you and alert you to product upgrades, special
        offers, updated information, and other new services from EnglishApps (in
        accordance with Canada’s Anti-Spam Legislation, as discussed below).
      </li>
      <p>
        The type of information that EnglishApps may collect from you includes,
        for example, your name, email address, and other information you choose
        to provide. Financial information is also collected by EnglishApps when
        you pay for the right to use English Apps' products and the Service.
      </p>
      <p>
        EnglishApps may collect Personal Information for other purposes where it
        has a legal duty or right to do so or where EnglishApps has a good faith
        belief that it is necessary to protect the property or rights of
        EnglishApps.
      </p>
      <p>
        EnglishApps does not collect your Personal Information for any other
        purposes, unless it has your consent.
      </p>
    </ul>
    <h2>COLLECTION</h2>
    <p>
      EnglishApps only collects Personal Information about an individual with
      the individual’s knowledge and consent. Generally, this means that
      EnglishApps collects personal information directly from the individual to
      whom it relates, whether via the Website, e-mail, telephone, or in person.
      Occasionally, EnglishApps may collect personal information from third
      party sources, but only with the knowledge and consent of the individual
      or where otherwise authorized by law.
    </p>
    <p>
      Visitors to the Website should also be aware that anonymous technical
      information may be collected by EnglishApps as a result of a visit to the
      Website. For example, this information may include your IP address,
      browser type, operating system, domain name, access times and referring
      website addresses. EnglishApps uses this anonymous technical information
      for purposes such as diagnosing problems with EnglishApps' servers,
      improving the operation and content of the Website and compiling aggregate
      and statistical information.
    </p>
    <p>
      EnglishApps will not attempt to link or match such anonymous technical
      information with any personally-identifiable information unless
      EnglishApps has your consent, EnglishApps (or its service providers) have
      detected or reasonably suspect any unlawful use of the Service or a
      security breach, or EnglishApps has a legal duty or right to do so.
    </p>
    <p>
      EnglishApps may use “cookies” on the Website. “Cookies” are small text
      files placed on computers that can collect and store a variety of
      information. Permanent cookies are stored indefinitely on a user’s hard
      drive unless manually deleted, while temporary cookies are automatically
      deleted from the user’s browser upon logging out of a website. Web
      browsers typically allow users to disable permanent and/or temporary
      cookies. For more general information on cookies see the Wikipedia article
      on HTTP Cookies.
    </p>
    <p>We set and use cookies to track your interactions with us:</p>
    <ul>
      <li>
        If you create an account with us, then we will use cookies for the
        management of the signup process and general administration. These
        cookies will usually be deleted when you log out. In some cases, they
        may remain afterward to remember your site preferences when logged out.
      </li>
      <li>
        We use cookies when you are logged in so that we can remember this fact.
        This prevents you from having to log in every single time you visit a
        new page. These cookies are typically removed or cleared when you log
        out to ensure that you can only access restricted features and areas
        when logged in.
      </li>
      <li>
        Our Website offers variable content as well as newsletter or email
        subscription services. Cookies may be used to remember if you are
        already registered and whether to show certain notifications which might
        only be valid to subscribed/unsubscribed users.
      </li>
      <li>
        From time to time we offer user surveys and questionnaires to provide
        you with interesting insights, helpful tools, or to understand our user
        base more accurately. These surveys may use cookies to remember who has
        already taken part in a survey or to provide you with accurate results
        after you change pages.
      </li>
      <li>
        We use local storage of your browser to store the content you created in
        EnglishApps. You can erease the data within EnglishApps using
        reset/erease buttons. You can alternatively use your browser's clear
        cached data feature to erease all storede data.
      </li>
      <li>
        When you submit data through a form such as those found on contact pages
        or comment forms cookies may be set to remember your user details for
        future correspondence.
      </li>
    </ul>
    <h2>USE AND DISCLOSURE</h2>
    <p>
      EnglishApps only uses or discloses Personal Information as reasonably
      required to facilitate the primary purposes described above.
    </p>
    <p>
      In some cases, EnglishApps may share personal information with third party
      service providers performing functions on EnglishApps' behalf, including,
      for example, vendors that provide information technology services or
      provide analytics or assessment services.
    </p>
    <p>
      EnglishApps will disclose your Personal Information, without notice, only
      if required to do so by law or in the good faith belief that such action
      is necessary to: (a) conform to the edicts of the law or comply with legal
      process served on EnglishApps or the Website; (b) protect and defend the
      rights or property of EnglishApps and its Website, and, (c) act in urgent
      circumstances to protect the personal safety of users of the Service or
      the Website, or the public.
    </p>
    <p>
      EnglishApps does not rent, sell or trade customer lists or other Personal
      Information.
    </p>
    <h2>CONSENT</h2>
    <p>
      You provide your consent to the collection, use, and disclosure of your
      Personal Information by EnglishApps when you provide your information to
      EnglishApps on the Website or when using the Service.
    </p>
    <p>
      You can deny or withdraw your consent to the collection, use and
      disclosure of your Personal Information at any time upon reasonable
      notice, subject to any legal or contractual requirements. If your consent
      is denied or withdrawn or you choose not to register or provide Personal
      Information, you can still use most of our Website. However, you will not
      be able to access areas that require registration such as the Service.
    </p>
    <p>
      If you decide to register, you will be able to select the kinds of
      information you want to receive from us by subscribing to various
      services, like our electronic newsletters. EnglishApps may use Personal
      Information to inform you of current and/or new products or services or
      other developments that EnglishApps believe will be of interest to you in
      accordance with applicable laws including Canada’s Anti-Spam Legislation.
      Such communications may be made by way of e-mail. Individuals provide
      their consent to EnglishApps contacting them in this manner when they use
      the Service.
    </p>
    <p>
      If you do not want us to communicate with you about other offers regarding
      EnglishApps' products, programs, events, or services by email you may
      unsubscribe using the supplied instructions.
    </p>
    <h2>SECURITY AND LOCATION</h2>
    <p>
      EnglishApps has taken strong measures to protect the security of your
      Personal Information and to ensure that your choices for its intended use
      are honored. We take precautions to protect your data from loss, misuse,
      unauthorized access or disclosure, alteration, or destruction. These
      measures include reasonable policies, procedures and safeguards (including
      physical, technological and organizational measures) designed to protect
      the security of Personal Information under our control.
    </p>
    <p>
      Your Personal Information is never shared outside the company without your
      permission, except under conditions explained above. Inside the company,
      data is stored in password-controlled servers with limited access.
    </p>
    <p>
      The protection of Personal Information is of paramount concern to
      EnglishAPps and EnglishApps is prepared to take appropriate and timely
      steps in the event of any incidents involving Personal Information in
      accordance with applicable privacy laws.
    </p>
    <h2>ACCESS</h2>
    <p>
      EnglishApps will provide you with access to your Personal Information that
      EnglishApps holds about you in accordance with applicable laws.
      EnglishApps reserves the right to require that any request for access to
      Personal Information be made in writing. Generally, there is no cost for
      such access. However, EnglishApps reserves the right to charge such costs
      on a case by case basis in accordance with applicable laws. You will be
      notified in advance if charges apply.
    </p>
    <p>
      EnglishApps will correct or amend the Personal Information in its files
      where it can be shown that the information is incorrect or incomplete.
    </p>
    <h2>CONTACT</h2>
    <p>
      We retain personal information only as long as is reasonable to fulfill
      the purposes for which the information was collected, or as otherwise
      required for legal or business purposes. We will continue to store
      information such as website visits, in order to improve the Service.
    </p>
    <h2>RETENTION</h2>
    <p>
      We retain personal information only as long as is reasonable to fulfill
      the purposes for which the information was collected, or as otherwise
      required for legal or business purposes. For greater certainty,
      EnglishApps does not retain any client data uploaded to an account through
      use of EnglishApps.
    </p>
    <p>
      We will continue to store information such as website visits, in order to
      improve the Service.
    </p>
    <h2>ADDITIONAL INFORMATION FOR RESIDENTS OF THE EUROPEAN UNION</h2>
    <p>
      The following information is provided for European users who may access
      the Website or use the Service.
    </p>
    <p>
      The European General Data Protection Regulation (GDPR) sets out
      obligations and rights with respect to the personal data of residents of
      the European Union. EnglishApps is committed to ensuring that it complies
      with the requirements of the GDPR. As such, this section outlines
      additional information relevant to residents of the European Union only.
      Please see the remainder of EnglishApps' Privacy Policy for more
      information on our privacy practices.
    </p>
    <p>Your Rights relating to your Personal Data</p>
    <p>
      Subject to some exceptions, the GDPR provides you with the following
      rights:
    </p>
    <ul>
      <li>
        Right of access: You have the right to information about whether and why
        we process your personal data and related information (for example, what
        personal data we are processing).
      </li>
      <li>
        Right of access: You have the right to information about whether and why
        we process your personal data and related information (for example, what
        personal data we are processing).
      </li>
      <li>
        Right to erasure: You have the right to ask us to delete personal data
        that we hold about you.
      </li>
      <li>
        Right to restrict or object to processing: You have the right to ask us
        to limit or stop our processing of your personal data in certain cases
        (for example, if the personal data we are processing about you is
        inaccurate or is for direct marketing purposes).
      </li>
      <li>
        Right to data portability: You have the right to ask us for a copy of
        personal data we hold about you and to transfer such data to another
        entity.
      </li>
      <li>
        Right to complain: We encourage you to contact us at the contact
        information above if you have any questions or concerns with our
        personal data practices. However, you also have the right to complain to
        regulatory authorities in your jurisdiction. Please contact us at the
        contact information above if you need information about the appropriate
        authority.
      </li>
    </ul>
    <p>
      You may exercise these rights or find out more about these rights by
      contacting EnglishApps.
    </p>
    <h2>Retention and Storage of Personal Data</h2>
    <p>
      EnglishApps is based in Canada and processes personal data in Canada. The
      European Commission recognizes Canada as providing an adequate level of
      protection for personal data.
    </p>
    <p>
      EnglishApps' service providers may process your Personal Information
      outside of Canada (please see above). When your information is processed
      outside of the European Union, it may be subject to the laws of and be
      accessible by legal authorities in such other jurisdictions. EnglishApps
      has taken appropriate technical, organizational, and legal steps to secure
      this information.
    </p>
  </Container>
);

export default PrivacyPolicy;
