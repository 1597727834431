import { API } from "aws-amplify";
import { useEffect, useState } from "react";

export const useFetch = (uri, dep) => {
  const [data, setData] = useState({});
  const [error, setError] = useState();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    API.get("apienglishreactamplify", uri, null)
      .then((response) => {
        setData(response);
      })
      .then(() => setLoading(false))
      .catch(setError);
    // eslint-disable-next-line
  }, [uri, dep]);

  return {
    loading,
    data,
    error,
  };
};
