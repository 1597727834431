import { faTrashAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, OverlayTrigger, Tooltip } from "react-bootstrap";

const ClearContent = ({ setBox, formStore, setForm, formKey }) => {
  // no need to clear api data load since the empty fields do not ally form
  // submission
  return (
    <OverlayTrigger placement="top" overlay={<Tooltip>Clear text box</Tooltip>}>
      {/* unfortunately FA disallows Button to pass the id, hence we need to
         handle all states here */}
      <Button
        variant="link"
        onClick={() => {
          setBox("");
          setForm({ ...formStore, [formKey]: "" });
        }}
      >
        <FontAwesomeIcon icon={faTrashAlt} pull="right" />
      </Button>
    </OverlayTrigger>
  );
};

export default ClearContent;
