import { memo } from "react";
import { Ratio } from "react-bootstrap";

const ContactUs = () => (
  <>
    <Ratio aspectRatio={1 / 2}>
      <iframe
        title="Google Form for English Apps"
        src={
          "https://docs.google.com/forms/d/e/1FAIpQLScK7N0v_" +
          "BcqsFkb-ftn0ZeTIcOy0yPridKkcsrpXVhXvPcdqw/viewform?embedded=true"
        }
      >
        Loading…
      </iframe>
    </Ratio>
  </>
);

export default memo(ContactUs);
