import { Authenticator } from "@aws-amplify/ui-react";
import { useRoutes } from "react-router";
import App from "./App";
import NoMatch from "./components/NoMatch";
import ClozeTest from "./pages/ClozeTest";
import ContactUs from "./pages/ContactUs";
import Dictionaries from "./pages/Dictionaries";
import DictionariesMultiple from "./pages/DictionariesMultiple";
import Worksheet from "./pages/Folder";
import FrequentWords from "./pages/FrequentWords";
import Highlight from "./pages/Highlight";
import Home from "./pages/Home";
import Matching from "./pages/Matching";
import MultipleChoice from "./pages/MultipleChoice";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import QuestionMaker from "./pages/QuestionMaker";
import SufPrefOutlet, { SufPrefs } from "./pages/SufPref";
import SynonymsOutlet, { Synonyms } from "./pages/Synonyms";
import TermsOfUse from "./pages/TermsOfUse";
import SentenceShuffler from "./pages/WordShuffler";

const MyAuth = ({ children }) => {
  return (
    <Authenticator loginMechanisms={["email"]} signUpAttributes={["name"]}>
      {children}
      {/* One can destructure user and pass to the component */}
      {/* {({ user }) => <Highlight user={user}/>} */}
    </Authenticator>
  );
};

let routes = [
  {
    path: "/",
    element: <App />,
    children: [
      { path: "*", element: <NoMatch /> },
      { element: <Home />, index: true },
      { path: "home", element: <Home />, index: true },
      { path: "dictionaries", element: <Dictionaries /> },
      { path: "dictionaries-multiple", element: <DictionariesMultiple /> },
      { path: "cloze-test", element: <ClozeTest /> },
      {
        path: "multiple-choice",
        element: (
          <MyAuth>
            {({ signOut }) => <MultipleChoice signOut={signOut} />}
          </MyAuth>
        ),
      },
      {
        path: "word-shuffler",
        element: (
          <MyAuth>
            {({ signOut }) => <SentenceShuffler signOut={signOut} />}
          </MyAuth>
        ),
      },
      {
        path: "sufpref",
        element: <SufPrefOutlet />,
        children: [
          {
            index: true,
            element: (
              <MyAuth>{({ signOut }) => <SufPrefs signOut={signOut} />}</MyAuth>
            ),
          },
          {
            path: ":sufprefID",
            element: (
              <MyAuth>{({ signOut }) => <SufPrefs signOut={signOut} />}</MyAuth>
            ),
          },
        ],
      },
      {
        path: "frequent-words",
        element: (
          <MyAuth>
            {({ signOut }) => <FrequentWords signOut={signOut} />}
          </MyAuth>
        ),
      },
      {
        path: "matching",
        element: (
          <MyAuth>{({ signOut }) => <Matching signOut={signOut} />}</MyAuth>
        ),
      },
      {
        path: "synant",
        element: <SynonymsOutlet />,
        children: [
          {
            index: true,
            element: (
              <MyAuth>{({ signOut }) => <Synonyms signOut={signOut} />}</MyAuth>
            ),
          },
          {
            path: ":synonymID",
            element: (
              <MyAuth>{({ signOut }) => <Synonyms signOut={signOut} />}</MyAuth>
            ),
          },
        ],
      },
      {
        path: "highlight",
        element: (
          <MyAuth>{({ signOut }) => <Highlight signOut={signOut} />}</MyAuth>
        ),
      },
      { path: "question-maker", element: <QuestionMaker /> },
      { path: "folder", element: <Worksheet /> },
      { path: "privacy-policy", element: <PrivacyPolicy /> },
      { path: "terms-of-use", element: <TermsOfUse /> },
      { path: "contact-us", element: <ContactUs /> },
    ],
  },
];

const MyRoutes = () => {
  return useRoutes(routes);
};

export default MyRoutes;
