import { Container } from "react-bootstrap";
import CloseButton from "../components/CloseButton";

const FolderItem = ({
  index,
  test_,
  closeTaskState,
  handleTaskClose,
  closeDirState,
  handleDirClose,
  children,
}) => {
  return (
    <>
      <Container className="border border-2 mb-2">
        <CloseButton
          order={test_.order}
          index={index}
          dirText={test_.dir_text}
          closeTaskState={closeTaskState}
          handleTaskClose={handleTaskClose}
          closeDirState={closeDirState}
          handleDirClose={handleDirClose}
        />
        {children}
      </Container>
    </>
  );
};

export default FolderItem;
