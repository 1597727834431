import { Container } from "react-bootstrap";

export const TheTaskSufPref = ({ data, sufprefWord, ansKeyState = true }) => {
  return (
    <>
      <Container className="askcontainer">
        <p>{data.defs["paragraph_"]}</p>
      </Container>
      <em>{sufprefWord}es to use</em>
      <p>{data.defs["options_"]}</p>
      {ansKeyState && (
        <>
          <code>Answer Key</code>
          <p>{data.defs["answer_key"]}</p>
        </>
      )}
    </>
  );
};

export default TheTaskSufPref;
