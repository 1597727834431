import getBigOrder from "../helpers/getBigOrder";

const saveToFolder = (
  event,
  workSheet,
  data,
  setWorkSheet,
  setShowFolder,
  setSaveFolder
) => {
  event.preventDefault();

  let newObject = { order: getBigOrder(workSheet), pageBreak: true, ...data };
  let newWorkSheet = [...workSheet, newObject];

  setWorkSheet(newWorkSheet);
  setShowFolder(true);
  setSaveFolder(true);
  setTimeout(() => {
    setSaveFolder(false);
  }, 1500);
};

export default saveToFolder;
