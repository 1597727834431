import { Container } from "react-bootstrap";

const TheTaskClozeTest = ({ data, ansKeyState = true }) => (
  <>
    {/* for verbs base we have empty options */}
    {data.defs["options_"] !== "" && (
      <Container className="askcontainer">
        <p>{data.defs["options_"]}</p>
      </Container>
    )}
    <Container style={{ marginTop: "15px" }}>
      <p>{data.defs["paragraph_"]}</p>
      {ansKeyState && (
        <>
          <code>Answer Key</code>
          <p>{data.defs["answer_key"]}</p>
        </>
      )}
    </Container>
  </>
);

export default TheTaskClozeTest;
