import { faTimesCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, Container, Table } from "react-bootstrap";

const TheTaskDictMultiple = ({ data, handleDefClose, isMain = false }) => {
  return (
    <Container className="askcontainer">
      <p>{data.paragraph}</p>
      <Table striped bordered hover>
        <thead>
          <tr>
            {isMain && <th></th>}
            <th>#</th>
            <th>Word</th>
            <th>Part of Speech</th>
            <th>Definition</th>
          </tr>
        </thead>
        <tbody>
          {data.defs.map((def_, i) => {
            return (
              <tr key={i}>
                {isMain && (
                  <td>
                    <Button
                      variant="link"
                      onClick={(e) => handleDefClose(i, e)}
                    >
                      <FontAwesomeIcon
                        icon={faTimesCircle}
                        className="close-button"
                      />
                    </Button>
                  </td>
                )}
                <td>{i + 1}</td>
                <td>{def_.word}</td>
                <td>{def_.pos}</td>
                <td>{def_.article}</td>
              </tr>
            );
          })}
        </tbody>
      </Table>
    </Container>
  );
};

export default TheTaskDictMultiple;
