import { faFolder } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { memo, useState } from "react";
import { Nav, Navbar, NavDropdown } from "react-bootstrap";
import { Link, useLocation } from "react-router-dom";

const NavBarMy = () => {
  // need to slice after the initial `/`
  let activeKey = useLocation().pathname.slice(1);

  const [expanded, setExpanded] = useState(false);

  // need href along with to so that active status work, otherwise Link only
  //  needs to
  const MyLink = ({ to, className, children }) => {
    return (
      <Nav.Link
        as={Link}
        to={to}
        href={to}
        className={className}
        onClick={() => setExpanded(false)}
      >
        {children}
      </Nav.Link>
    );
  };

  const MyDropdownItem = ({ to, children }) => {
    return (
      <NavDropdown.Item
        style={{ textAlign: "center" }}
        as={Link}
        to={to}
        onClick={() => setExpanded(false)}
      >
        {children}
      </NavDropdown.Item>
    );
  };

  return (
    <Navbar
      expanded={expanded}
      bg="dark"
      variant="dark"
      expand="lg"
      fixed="top"
      className="my-navbar"
    >
      <Navbar.Brand as={Link} to="home" onClick={() => setExpanded(false)}>
        English Apps
      </Navbar.Brand>
      <Navbar.Toggle
        aria-controls="responsive-navbar-nav"
        onClick={() => setExpanded(expanded ? false : true)}
      />
      <Navbar.Collapse id="responsive-navbar-nav">
        <Nav fill activeKey={activeKey}>
          <NavDropdown title="Dictionaries" id="basic-nav-dropdown">
            <MyDropdownItem as={Link} to="dictionaries">
              Word Lookup
            </MyDropdownItem>
            <MyDropdownItem as={Link} to="dictionaries-multiple">
              Vocabulary
            </MyDropdownItem>
          </NavDropdown>
          <MyLink to="cloze-test">Cloze Test</MyLink>
          <MyLink to="multiple-choice">Multiple Choice Test</MyLink>
          <MyLink to="word-shuffler">Sentence Shuffler</MyLink>
          <NavDropdown title="Suffix/Prefix Matcher" id="basic-nav-dropdown">
            <MyDropdownItem as={Link} to="sufpref/suffix-matcher">
              Suffix Matcher
            </MyDropdownItem>
            <MyDropdownItem as={Link} to="sufpref/prefix-matcher">
              Prefix Matcher
            </MyDropdownItem>
          </NavDropdown>

          <MyLink to="frequent-words">Frequent Word</MyLink>
          <MyLink to="matching">Matching Task</MyLink>
          <NavDropdown
            title="Synonyms & Antonyms Matching"
            id="basic-nav-dropdown"
          >
            <MyDropdownItem as={Link} to="synant/synonyms-basic">
              Synonyms Basic
            </MyDropdownItem>
            <MyDropdownItem as={Link} to="synant/synonyms-advanced">
              Synonyms Advanced
            </MyDropdownItem>
            <MyDropdownItem as={Link} to="synant/antonyms">
              Antonyms
            </MyDropdownItem>
          </NavDropdown>
          <MyLink to="highlight">Word Highlighter</MyLink>
          {/* <MyLink to="question-maker">Question Maker</MyLink> */}
          <MyLink to="folder">
            <FontAwesomeIcon icon={faFolder} style={{ fontSize: "1.5rem" }} />
          </MyLink>
        </Nav>
      </Navbar.Collapse>
    </Navbar>
  );
};

export default memo(NavBarMy);
