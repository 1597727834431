import { Container } from "react-bootstrap";

export const TheTaskHighlight = ({ data }) => {
  return (
    <>
      <Container className="askcontainer">
        <p
          className={data.highlightClass}
          dangerouslySetInnerHTML={{ __html: data.defs["paragraph_"] }}
        />
      </Container>
    </>
  );
};

export default TheTaskHighlight;
